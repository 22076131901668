import React from 'react';
import * as _ from 'lodash';
import { observer, inject } from 'mobx-react';
import Container from '../../layouts/Container';
import EnToggle from '../../components/form/EnToggle';
import Notification from '../../components/common/Notification';
import Loader from '../../components/common/Loader';
import config from '../../config';
import { isAllow } from '../../utils/permission';
import FormGroup from '../../components/form/FormGroup';
import EnText from '../../components/form/EnText';
import SimplePagination from '../../components/common/SimplePagination';
export class TypeOfWork extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      typeofwork: '',
      typeofworks: [],
      query: {
        typeofwork: '',
        limit: 10,
        page: 1,
        totalPages: 1,
        totalItems: 1
      },
    };
  }

  async componentDidMount() {
    try {
      this.checkPermission();
      await this.getTypeOfWorks()
    } catch (err) {
      this.noti.error(err.message);
    }
  }

  onAddNew() {
    this.props.history.push(`${config.publicUrl}/superadmin/typeofwork/add`);
  }

  onEdit(item) {
    if (item) {
      this.props.history.push(`${config.publicUrl}/superadmin/typeofwork/${item._id}/edit`);
    }
  }

  async onContractorVisibleToggle(index, item) {
    this.setState({ isLoading: true });
    const typeofworks = await this.props.typeofwork.toggleContractorVisible(index, item._id, !item.contractor_visible);
    this.setState({ typeofworks, isLoading: false });
  }

  onUpdateSearch = (search) => {
    this.setState({
      typeofwork: search
    })
  }

  onUpdateQuery = (key, value) => {
    const query = this.state.query
    _.set(query, key, value)
    this.setState(query)
  }

  onClickSearch = () => {
    this.onUpdateQuery('typeofwork', this.state.typeofwork)
    this.onUpdateQuery('page', 1)
    this.getTypeOfWorks()
  }

  onSetPage = (page) => {
    this.onUpdateQuery('page', page)
    this.getTypeOfWorks()
  }
  
  checkPermission(){
    if(!isAllow("VIEW_A_LIST_OF_WORK_TYPE")){
      this.props.history.push(`${config.publicUrl}`);
    }
  }

  async getTypeOfWorks() {
    this.setState({ isLoading: true })
    try {
      const { items, limit, page, totalPages, totalItems } = await this.props.typeofwork.getPaginatedTypeOfWorks(this.state.query)
      this.setState({
        typeofworks: items,
        query: {
          ...this.state.query,
          limit,
          page,
          totalPages,
          totalItems,
        },
      });
    } finally {
      this.setState({ isLoading: false })
    }
  }

  getTableRows(items) {
    const { page, limit } = this.state.query
    return _.orderBy(items, ['name']).map((item, index) => {
      return (
        <tr key={item._id}>
          <td className="text-center">{index + 1 + ((page - 1) * limit)}</td>
          <td>{item.name}</td>
          <td className="text-center">
            <EnToggle
              checked={item.contractor_visible === true}
              disabled={isAllow("EDIT_CONTRACTOR_VIEW_STATUS") ? false:true}
              onChange={this.onContractorVisibleToggle.bind(this, index, item)} />
          </td>
          <td className="text-center">
            <button disabled={isAllow("EDIT_A_WORK_TYPE_INFORMATION") ? false:true} className="btn btn-xs" title="แก้ไข" data-tip="แก้ไข" onClick={this.onEdit.bind(this, item)}>
              <span className="fa fa-pencil" aria-hidden="true" />
            </button>
          </td>
        </tr>
      );
    });
  }

  render() {
    const { isLoading } = this.state
    // const userInfo = this.props.auth.getUserInfo();
    // const channel = userInfo.channel ? userInfo.channel.channelName : '';
    // const channel = this.props.auth.getChannelName() || '';
    // const isSuperadmin = this.props.auth.isSuperAdmin();
    // const listTypeofwork = isSuperadmin ? items : items.filter(t => t.channel === channel);
    let rows = this.getTableRows(this.state.typeofworks);

    return (
      <Container isAdmin>
        <div className="row">
          <Loader show={isLoading} />
          <Notification ref={(ref) => { this.noti = ref; }} />
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">ประเภทงาน</h4>
              </div>
              <div className="card-content">
                <div className="row">
                  <FormGroup label="ค้นหา:" containerClassName="col-md-4">
                    <div className='form-group has-feedback has-clear'>
                      <div className='input-group'>
                        <EnText className='form-control' placeholder='ค้นหาด้วยชื่อประเภทงาน' value={this.state.typeofwork} onChange={(e) => this.onUpdateSearch(e.target.value)} onKeyPress={(e) => { if (e.key === 'Enter') this.onClickSearch() }}  />
                        <span className='input-group-btn'>
                          <button
                            id='search-button'
                            type='button'
                            className='btn btn-primary btn-outline-secondary btn-custom'
                            onClick={() => { this.onClickSearch() }}
                          >
                            <i className='fa fa-search' aria-hidden='true' />
                          </button>
                        </span>
                      </div>
                    </div>
                  </FormGroup>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="table table-bordered table-hover has-pagination">
                        <thead>
                          <tr>
                            <th width="10%" className="text-center">ลำดับที่</th>
                            <th width="75%" className="text-center">ชื่อประเภทงาน</th>
                            <th width="10%" className="text-center">แสดงหน้าช่าง</th>
                            <th width="5%" className="text-center">
                              <button disabled={isAllow("CREATE_A_NEW_WORK_TYPE") ? false:true} className="btn btn-success" title="สร้างใหม่" data-tip="สร้างใหม่" onClick={this.onAddNew.bind(this)}>
                                <span className="fa fa-plus btn-icon" aria-hidden="true" />สร้างใหม่
                              </button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {rows}
                        </tbody>
                      </table>
                    </div>
                    <div className='row'>
                      <div className='col-md-12'>
                        <SimplePagination pagination={this.state.query} onSetPage={this.onSetPage} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('typeofwork', 'auth')(observer(TypeOfWork));
