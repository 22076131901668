import * as _ from 'lodash';
import React from 'react';
import { observer, inject } from 'mobx-react';
import { FormValidation, required } from '../../../components/form/FormValidation';
import Loader from '../../../components/common/Loader';
import FormGroup from '../../../components/form/FormGroup';
import EnButton from '../../../components/form/EnButton';
import EnNumeric from '../../../components/form/EnNumeric';
import EnDropDown from '../../../components/form/EnDropDown';
import { EnTagSelect } from '../../../components/form/EnTagSelect';
import EnToggle from '../../../components/form/EnToggle';
import Container from '../../../layouts/ContainerContractor';
import Notification from '../../../components/common/Notification';
// import common from '../../../utils/common';
import config from '../../../config';

const typeOfWorkOptions = [{
  name: 'สำรวจ',
  value: 'S',
}, {
  name: 'ติดตั้ง',
  value: 'I',
}];

export class WorkArea extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.match.params.id,
      items: [],
    };
  }

  // saveTimesOfWork(val) {
  //   this.props.contractor.saveInfo('timesOfWork', val);
  // }

  saveInfo(key, val) {
    this.props.contractor.saveInfo(key, val);
  }

  onDistrictChanged(selectedItem, districtCode, e) {
    let items = _.cloneDeep(this.state.items);
    if (e.indexOf('ALL') >= 0) {
      let district = districtCode.map(d => d.value);
      let item = items.find(i => i.key === selectedItem.key);
      if (!_.isNil(item) && !_.isEmpty(item)) {
        item.districtCode = district;
      }
    } else {
      let district = e.split(',');
      let item = items.find(i => i.key === selectedItem.key);
      if (!_.isNil(item) && !_.isEmpty(item)) {
        item.districtCode = district;
      }
    }
    this.setState({
      items,
    });
  }

  getNotSelectedList(selectedId) {
    let miscStore = this.props.misc.toJS();
    let province = miscStore.miscData.province;
    return province.filter(c => {
      if (selectedId && c.code === selectedId) {
        return true;
      } else {
        let item = this.state.items.find(i => i.provinceCode === c.code);
        return _.isNil(item) || _.isEmpty(item);
      }
    });
  }

  async addNewItem() {
    let items = _.cloneDeep(this.state.items);
    const notSelectedList = this.getNotSelectedList();
    if (notSelectedList.length > 0) {
      items.unshift({
        key: (new Date()).getTime(),
        provinceCode: notSelectedList[0].code,
        districtCode: [],
        districtMaster: await this.props.misc.getDistrictByCode(notSelectedList[0].code),
      });
      this.setState({
        items,
      });
    }
  }

  deleteItems(item, e) {
    // console.log('item >>>>>>>>>>>>>>>>', item);
    let data = this.state.items.filter(d => d.key !== item.key);
    this.setState({
      items: data,
    });
  }

  showItem() {
    let items = (this.state.items || []).map((item, index) => {
      let provinceOptions = this.getNotSelectedList(item.provinceCode).map((c, index) => {
        return <option key={index} value={c.code}>{c.name}</option>;
      });
      let districtOptions = [];
      let districtCode = [];
      if (item.provinceCode) {
        districtOptions = item.districtMaster.map(d => {
          return ({
            label: d.name,
            value: d.code,
          });
        });

        districtCode = districtOptions;
        if (item.districtMaster.length !== item.districtCode.length) {
          districtOptions = [{ label: 'เลือกทั้งหมด', value: 'ALL' }, ...districtOptions];
        }
      }
      return (
        <div key={`item-${index}`} className="card item-card">
          <div className="card-content">
            <FormGroup label="จังหวัด">
              <EnDropDown value={item.provinceCode} onChange={this.onProvinceChanged.bind(this, item)}>
                {provinceOptions}
              </EnDropDown>
            </FormGroup>
            <FormGroup label="อำเภอ" id={`amphor_${index}`}>
              <EnTagSelect
                closeOnSelect={false}
                options={districtOptions}
                onTagChange={this.onDistrictChanged.bind(this, item, districtCode)}
                value={item.districtCode}
                searchable={false}
                validations={[required]}
              />
            </FormGroup>
            {
              this.state.items.length > 1 &&
              <EnButton className="btn-danger pull-right" onClick={this.deleteItems.bind(this, item)}>
                <i className="fa fa-trash btn-icon" aria-hidden="true" />ลบ
              </EnButton>
            }
          </div>
        </div>
      );
    });
    return items;
  }

  async onProvinceChanged(selectedItem, e) {
    let id = e.target.value;
    let items = _.cloneDeep(this.state.items);
    let dis = await this.props.misc.getDistrictByCode(id);
    let item = items.find(i => i.key === selectedItem.key);
    if (!_.isNil(item) && !_.isEmpty(item)) {
      item.provinceCode = id;
      item.districtCode = [];
      item.districtMaster = dis;
    }
    this.setState({
      items,
    });
  }

  validation() {
    let check = this.state.items.map(d => {
      return !(_.isEmpty(d.districtCode) || _.indexOf(d.districtCode, '') >= 0);
    });
    let isValid = _.indexOf(check, false);
    return isValid < 0;
  }

  onSubmit() {
    const contractorId = this.state.id;
    // const calendarFilter = this.props.calendar.toJS().calendarFilter;
    // let qs = {};
    // if (calendarFilter) {
    //   qs = calendarFilter;
    // }
    this.noti.success('แก้ไขข้อมูลเรียบร้อย', 'สำเร็จ');
    setTimeout(() => {
      this.props.history.push(`${config.publicUrl}/contractors/${contractorId}/calendar`);
    }, 1000);
  }

  async clickSubmit() {
    try {
      this.props.contractor.saveInfo('areaOfWork', this.state.items);
      await this.props.contractor.updateContractorInfo(this.state.id, 'workarea');
      this.onSubmit();
    } catch (error) {
      this.noti.error(error.message);
    }
  }

  async componentDidMount() {
    try {
      await this.props.misc.getAllProvince();
      let data = await this.props.contractor.getContractor({
        id: this.state.id,
      });

      if (data && _.isObject(data)) {
        if (data.area_of_work.length && _.every(data.area_of_work, (a) => _.isObject(a))) {
          let items = [];
          data.area_of_work.map(async (w, index) => {
            items.push({
              key: index,
              provinceCode: w.province_id,
              districtCode: w.district_id,
              districtMaster: await this.props.misc.getDistrictByCode(w.province_id),
            });
            this.setState({
              items,
            });
          });
        } else {
          let district = await this.props.misc.getDistrictByCode('10');
          this.setState({
            items: [
              {
                key: (new Date()).getTime(),
                provinceCode: '10',
                districtCode: [],
                districtMaster: district,
              },
            ],
          });
        }
      }
    } catch (error) {
      this.noti.error(error.message);
    }
  }

  onCalendarTypeChanged = (e) => {
    if (e && e.value) {
      this.props.contractor.saveInfo('calendarType', e.value);
    }
  }

  render() {
    let contractorStore = this.props.contractor.toJS();
    let showAddButton = '';
    if (this.state.items.length) {
      showAddButton = this.state.items[0].districtCode;
    }
    let contractor = this.props.contractor.toJS().info;
    let typeOfWorkToggle = typeOfWorkOptions.map((item, index) => {
      return (
        <div style={{ paddingLeft: '15px', marginBottom: '5px', marginTop: '5px' }}>
          <EnToggle key={index} checked={item.value === contractor.calendarType} label={item.name} onChange={this.onCalendarTypeChanged.bind(this, item)} />
        </div>
      );
    });
    return (
      <Container>
        <Notification ref={(ref) => { this.noti = ref; }} />
        <Loader show={contractorStore.isLoading || contractorStore.isSumitting} />
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">แก้ไขข้อมูล</h4>
                <p className="category">กรุณากรอกข้อมูลให้ครบถ้วน</p>
              </div>
              <div className="card-content">
                <FormValidation>
                  <FormGroup containerClassName="p4-font-input time-work col-md-12" label="ปฎิทินหลัก">
                    <div className="col-md-12">
                      {typeOfWorkToggle}
                      {/* <label>
                          <input
                            type="radio"
                            value="S"
                            checked={contractor.calendarType === 'S'}
                            onClick={this.onCalendarTypeChanged.bind(this)}
                            readOnly />&nbsp;&nbsp;สำรวจ
                        </label>
                        &nbsp;&nbsp;&nbsp;
                        <label>
                          <input
                            type="radio"
                            value="I"
                            checked={contractor.calendarType === 'I'}
                            onClick={this.onCalendarTypeChanged.bind(this)}
                            readOnly />&nbsp;&nbsp;ติดตั้ง
                        </label> */}
                    </div>
                  </FormGroup>
                  <div className="col-md-12" />
                  <FormGroup containerClassName="p4-font-input time-work col-md-12" label="งานสำรวจ">
                    <div className="col-md-12" >
                      <div className="col-md-6" >
                        <center> <h7>เช้า (งาน)</h7></center>
                        <EnNumeric
                          min={1}
                          onChange={this.saveInfo.bind(this, 'morningSurveyPerDay')}
                          value={contractor.surveyPerDay.morning} />
                      </div>
                      <div className="col-md-6" >
                        <center> <h7>บ่าย (งาน)</h7></center>
                        <EnNumeric
                          min={1}
                          onChange={this.saveInfo.bind(this, 'afternoonSurveyPerDay')}
                          value={contractor.surveyPerDay.afternoon} />
                      </div>
                    </div>
                  </FormGroup>
                  &nbsp;
                  <FormGroup containerClassName="p4-font-input time-work col-md-12" label="งานติดตั้ง">
                    <div className="col-md-12" >
                      <div className="col-md-6" >
                        <center> <h7>เช้า (งาน)</h7></center>
                        <EnNumeric
                          min={1}
                          onChange={this.saveInfo.bind(this, 'morningInstallationPerDay')}
                          value={contractor.installationPerDay.morning} />
                      </div>
                      <div className="col-md-6" >
                        <center> <h7>บ่าย (งาน)</h7></center>
                        <EnNumeric
                          min={1}
                          onChange={this.saveInfo.bind(this, 'afternoonInstallationPerDay')}
                          value={contractor.installationPerDay.afternoon} />
                      </div>
                    </div>
                  </FormGroup>
                  &nbsp;
                  <div className="col-md-12">
                    <span className="form-label">พื้นที่รับงาน</span>
                    <div className="card-content">
                      {
                        showAddButton.length > 0 &&
                        <EnButton className="btn btn-success pull-right" onClick={this.addNewItem.bind(this)}>
                          <i className="fa fa-plus btn-icon" aria-hidden="true" />เพิ่มพื้นที่รับงาน
                        </EnButton>
                      }
                      {this.showItem()}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <EnButton className="btn-info pull-right" disabled={!this.validation()} onClick={this.clickSubmit.bind(this)}>
                      <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
                    </EnButton>
                  </div>
                </FormValidation>
              </div>
            </div>
          </div>
        </div >
      </Container >
    );
  }
}

export default inject('contractor', 'misc', 'calendar')(observer(WorkArea));
