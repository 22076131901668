import * as _ from 'lodash';
import BaseStore from './BaseStore';
import JobStore from './JobStore';
import config from '../config';
import datetime from '../utils/datetime';
import http from '../utils/http';
import moment from 'moment';
import common from '../utils/common';
import qs from 'query-string';
import { pick, pickBy } from 'lodash';

// TODO get vat rate from api
export const vatRate = 7;

export class QuotationStore extends BaseStore {
  constructor() {
    super();
    this.observable({
      isLoading: false,
      isSubmitting: false,
      info: this.createDefaultInfo(),
      installmentsQuotationInfo: this.createInstallmentsQuotationInfo(),
      subQuotationsInfo: [],
      quotationInfo: {},
      optionsQuotation: [],
      mainQuotation: {},
      calendarSubQuotation: {},
      showTabInstallment: false,
      listQuotationUsed: [],
      refCode: undefined,
      typeOfJob: undefined,
      selectPeriods: 1,
      defaultMonth: [
        { label: '1 งวด', value: 1 },
        { label: '2 งวด', value: 2 },
        { label: '3 งวด', value: 3 },
        { label: '4 งวด', value: 4 },
        { label: '5 งวด', value: 5 },
      ],
      oldValue: [],
      oldPeriods: undefined,
      saleModel: undefined
    });
  }

  clearInfo() {
    this.info = this.createDefaultInfo();
  }

  setInstallment = (value) => {
    this.installment = value;
  }

  saveValue = (key, value) => {
    this[key] = value;
  }

  savePeriods = (value) => {
    this.selectPeriods = value;
  }

  saveDraftPublic = (key, value) => {
    this.quotationInfo.draft_quotation[key].is_public = value;
  }
  
  saveDraftFinal = (key, value) => {
    this.quotationInfo.draft_quotation[key].is_final = value;
    if(value){
      this.saveDraftPublic(key, value);
    }
    // reset all is_public
    for(let i = 0; i< this.quotationInfo.draft_quotation.length; i++){
      if(i!==key && value){
        this.quotationInfo.draft_quotation[i].is_public = false;
      }
    }
  }

  deleteDraft = (key, value) => {
    this.quotationInfo.draft_quotation[key].is_delete = value;
  }

  createInstallmentsQuotationInfo() {
    return {
      job_code: "",
      created_by: "",
      sub_quotation: this.subQuotationsInfo
    }
  }

  createSubQuotations(no) {
    return {
      installment: no,
      installment_name: "",
      no: no,
      date: new Date().toISOString(),
      issue_date: this.info.issueDate,
      expired_date: this.info.expiredDate,
      job_start_date: "",
      job_end_date: "",
      items: [],
      sub_total: "",
      discount_total: "",
      net_total: "",
      vat: "",
      vat_rate: this.info.vatRate,
      net_exclude_vat: "",
      discount_coupon: "",
      grand_total: "",
      remark: this.info.remark,
      periods: this.mainQuotation.periods,
      files: [],
      is_contractor_quotation: false
    }
  }

  createDefaultInfo() {
    return {
      type: 'individual',
      name: '',
      address: {
        no: '',
        province: {
          name: '',
          code: '',
        },
        district: {
          name: '',
          code: '',
        },
        subDistrict: {
          name: '',
          code: '',
        },
        postcode: '',
      },
      taxId: '',
      branch: '',
      phone: '',
      details: [this.createDefaultDetail()],
      depositDetails: [this.createDefaultDepositDetail()],
      oldDepositDetails: undefined,
      remark: '',
      issueDate: moment(),
      expiredDate: moment().add(30, 'days'),
      subTotal: 0.00,
      discountTotal: 0.00,
      netTotal: 0.00,
      // subTotalExcludeVat: 0.00,
      vat: 0.00,
      vatRate,
      netExcludeVat: 0.00,
      discountCoupon: 0.00,
      grandTotal: 0.00,
      periods: [{ amount: 0, status: 'pending', ref: '' }],
      contractorInfo: {
        subTotal: 0.00,
        discountTotal: 0.00,
        netTotal: 0.00,
        netExcludeVat: 0.00,
        whTax: 0.00,
        vat: 0.00,
        grandTotal: 0.00,
      },
      requireWh: false,
      isPaid: false,
      quotationDetailFiles: [],
    };
  }

  createDefaultDetail() {
    return {
      code: '',
      name: '',
      unit: '',
      qty: 1,
      price: 0.00,
      discount: 0.00,
      amount: 0.00,
      contractor_cost: 0.00,
      contractor_discount: 0.00,
      contractor_amount: 0.00,
      wh_flag: this.isWhFlag(),
    };
  }

  createDefaultDepositDetail() {
    return {
      code: '',
      name: '',
      unit: '',
      qty: 1,
      price: 0.00,
      discount: 0.00,
      amount: 0.00,
      contractor_cost: 0.00,
      contractor_discount: 0.00,
      contractor_amount: 0.00,
      wh_flag: this.isWhFlag(),
      is_deposit: true,
    };
  }

  createSurveyDiscountDetail(items = []) {
    return items.map((item) => ({
      code: item.code,
      name: item.name,
      unit: item.unit,
      qty: item.qty,
      price: item.price,
      discount: item.discount,
      amount: item.amount,
      contractor_cost: item.contractor_cost,
      contractor_discount: item.contractor_discount,
      contractor_amount: item.contractor_amount,
      wh_flag: this.isWhFlag(),
      is_deposit: true,
    }));
  }

  toAddressData(address) {
    return {
      subDistrict: address.subDistrict.name,
      subDistrictCode: address.subDistrict.code,
      district: address.district.name,
      districtCode: address.district.code,
      province: address.province.name,
      provinceCode: address.province.code,
      postCode: address.postcode,
    };
  }

  saveAddressObject(obj) {
    this.info.address.province.name = obj.pname;
    this.info.address.province.code = obj.pcode;
    this.info.address.district.name = obj.aname;
    this.info.address.district.code = obj.acode;
    this.info.address.subDistrict.name = obj.dname;
    this.info.address.subDistrict.code = obj.dcode;
    this.info.address.postcode = obj.zcode;
  }

  saveInfo(key, value) {
    this.info[key] = value;
  }

  saveAddressInfo(key, value) {
    this.info.address[key] = value;
  }

  saveDetail(index, key, value) {
    this.info.details[index][key] = value;
  }

  saveDetailItem(index, object) {
    this.info.details[index] = object;
  }

  saveSubQuotation(index, key, value) {
    this.subQuotationsInfo[index][key] = value;
  }

  handleOptionListQuotations = () => {
    this.listQuotationUsed = this.subQuotationsInfo.reduce((pre, item)=> {
        return pre.concat(item.items.toJS())
    }, []).map((item)=> item.name)
  }

  addInstallmentsQuotation() {
    const no = this.subQuotationsInfo.length + 1
    const info = this.info.depositDetails.toJS()
    this.subQuotationsInfo.push(this.createSubQuotations(no.toString()))
    if(no === 1) {
      const filterCouponDiscount = this.optionsQuotation.filter(item=> item.amount < 0 && item.is_deposit ? item.is_deposit: false)
      if(filterCouponDiscount.length >0) {
        filterCouponDiscount.forEach((item) => {
          this.subQuotationsInfo[0].items.push(item)
        })
      }
    }
    this.subQuotationsInfo[no - 1].items.push(this.createDefaultDetail())
  }

  addRowListQuotation(index) {
    this.subQuotationsInfo[index].items.push(this.createDefaultDetail())
  }

  deleteRowListQuotation(indexinstallments, indexRow) {
    this.subQuotationsInfo[indexinstallments].items.splice(indexRow, 1)
    this.calculateSummaryInstallment(indexinstallments)
  }

  deleteListQuotation (index) {
    this.subQuotationsInfo.splice(index, 1)
  }

  calculateSummaryInstallment (indexinstallments) {
    const info = this.subQuotationsInfo[indexinstallments].items;
    const result = info.reduce((acc, cur) => {
      return {
        subTotal: cur.price ? acc.subTotal + (parseFloat(cur.qty || '1') * parseFloat(cur.price || 0)) : acc.subTotal,
        discountTotal: cur.discount ? acc.discountTotal + parseFloat(cur.discount) : acc.discountTotal,
        totalContractorDiscount: cur.contractor_discount ? acc.totalContractorDiscount + parseFloat(cur.contractor_discount) : acc.totalContractorDiscount,
        totalContractorCost: cur.contractor_cost ? acc.totalContractorCost + (parseFloat(cur.qty || '1') * parseFloat(cur.contractor_cost || 0)) : acc.totalContractorCost,
        whTaxTotal: cur.wh_flag ? (acc.whTaxTotal || 0) + (cur.contractor_amount) : (acc.whTaxTotal || 0),
      };
    }, {
      subTotal: 0.00,
      discountTotal: 0.00,
      totalContractorDiscount: 0.00,
      totalContractorCost: 0.00,
      whTaxTotal: 0.00,
    });
    const subQuotationsInfo = this.subQuotationsInfo[indexinstallments];
    
    subQuotationsInfo.sub_total = result.subTotal;
    subQuotationsInfo.discount_total = parseFloat(result.discountTotal)
    subQuotationsInfo.net_total = subQuotationsInfo.sub_total  - subQuotationsInfo.discount_total;
    subQuotationsInfo.net_exclude_vat = parseFloat((subQuotationsInfo.net_total / 1.07).toFixed(2));
    subQuotationsInfo.vat = parseFloat((subQuotationsInfo.net_total - subQuotationsInfo.net_exclude_vat).toFixed(2));
    subQuotationsInfo.grand_total = subQuotationsInfo.net_total - (info.discount || 0);
    const periods = {...subQuotationsInfo.periods[0], amount: subQuotationsInfo.grand_total }
    subQuotationsInfo.periods = [periods]
  }

  saveSubQuotationAndCalculate(indexinstallments, indexRow, value) {
    this.subQuotationsInfo[indexinstallments].items[indexRow] = value
    this.calculateSummaryInstallment(indexinstallments)
  }

  checkInstDayBlockStore(jobInfo, date) {
    if (!(this.calendarSubQuotation.unavailableInstDate && this.calendarSubQuotation.instContractors)) {
      return;
    }
    let unavailableInstDate = this.calendarSubQuotation.unavailableInstDate.toJS()
    let instContractors = this.calendarSubQuotation.instContractors.toJS()
    if (jobInfo.surveyContractor && instContractors) {
      const contractor = instContractors.find(c => c._id === jobInfo.surveyContractor);
      if (contractor) {
        const unavailable_date =  contractor.unavailable_date ? contractor.unavailable_date.toJS(): contractor.unavailable_date
        unavailableInstDate = common.unavailableDate([contractor], unavailable_date);
      }
    }
    if (Array.isArray(unavailableInstDate) && unavailableInstDate.length) {
      const dateBlock = unavailableInstDate.find(u => datetime.isEqual(date, u));
      return !!dateBlock;
    } else {
      return false;
    }
  }

  saveDetailAndCalculate(index, key, value) {
    const info = this.info;
    const detail = info.details[index];
    if (key === 'wh_flag') {
      detail[key] = !!detail[key] !== true;
    } else {
      detail[key] = !isNaN(value) ? value : 0; // value.replace(/[^\d\.\-eE+]/g, '');
    }

    detail.amount = (detail.price * detail.qty) - detail.discount;
    detail.contractor_amount = ((detail.contractor_cost || 0) * detail.qty) - (detail.contractor_discount || 0);
    this.calculateTotal();
  }

  mergeDetailData() {
    if (this.info.depositDetails && this.info.depositDetails.length && this.info.details &&
      this.info.depositDetails[0].name) {
      this.info.details.push(this.info.depositDetails[0]);
    }
  }

  saveDepositDetail(index, key, value) {
    this.info.depositDetails[index][key] = value;
  }

  saveDepositDetailAndCalculate(index, key, value) {
    const info = this.info;
    const detail = info.depositDetails[index];
    if (key === 'wh_flag') {
      detail[key] = !!detail[key] !== true;
    } else {
      detail[key] = !isNaN(value) ? value : 0; // value.replace(/[^\d\.\-eE+]/g, '');
    }

    detail.amount = (detail.price * detail.qty) - detail.discount;
    detail.contractor_amount = ((detail.contractor_cost || 0) * detail.qty) - (detail.contractor_discount || 0);
    this.calculateTotal();
  }

  saveDiscountCoupon(value) {
    this.info.discountCoupon = value;
    this.info.grandTotal = this.info.netTotal - this.info.discountCoupon;
  }

  calculateTotal() {
    const info = this.info;
    const contractorInfo = this.info.contractorInfo;
    const depositDetails = info.depositDetails && info.depositDetails.length ? info.depositDetails[0] : {};
    const result = info.details.reduce((acc, cur) => {
      return {
        subTotal: cur.price ? acc.subTotal + (parseFloat(cur.qty || '1') * parseFloat(cur.price || 0)) : acc.subTotal,
        discountTotal: cur.discount ? acc.discountTotal + parseFloat(cur.discount) : acc.discountTotal,
        totalContractorDiscount: cur.contractor_discount ? acc.totalContractorDiscount + parseFloat(cur.contractor_discount) : acc.totalContractorDiscount,
        totalContractorCost: cur.contractor_cost ? acc.totalContractorCost + (parseFloat(cur.qty || '1') * parseFloat(cur.contractor_cost || 0)) : acc.totalContractorCost,
        whTaxTotal: cur.wh_flag ? (acc.whTaxTotal || 0) + (cur.contractor_amount) : (acc.whTaxTotal || 0),
      };
    }, {
      subTotal: 0.00,
      discountTotal: 0.00,
      totalContractorDiscount: 0.00,
      totalContractorCost: 0.00,
      whTaxTotal: 0.00,
    });
    info.subTotal = result.subTotal;
    info.discountTotal = parseFloat(result.discountTotal) + parseFloat(depositDetails.discount || 0);
    info.netTotal = info.subTotal - info.discountTotal;
    info.netExcludeVat = parseFloat((info.netTotal / 1.07).toFixed(2));
    info.vat = parseFloat((info.netTotal - info.netExcludeVat).toFixed(2));
    info.grandTotal = info.netTotal - (info.discountCoupon || 0);

    contractorInfo.subTotal = result.totalContractorCost;
    contractorInfo.discountTotal = result.totalContractorDiscount + (+depositDetails.contractor_discount || 0);
    contractorInfo.netTotal = contractorInfo.subTotal - contractorInfo.discountTotal;
    contractorInfo.netExcludeVat = parseFloat((contractorInfo.netTotal / 1.07).toFixed(2));
    contractorInfo.whTax = (result.whTaxTotal / 1.07) * (config.scg.whTaxRate / 100);
    contractorInfo.vat = parseFloat((contractorInfo.netTotal - contractorInfo.netExcludeVat).toFixed(2));
    contractorInfo.grandTotal = contractorInfo.netTotal - (contractorInfo.discountCoupon || 0) - (contractorInfo.whTax || 0);
  }

  addDetail() {
    this.info.details.push(this.createDefaultDetail());
  }

  deleteDetail(index) {
    const info = this.toJS().info;
    const details = _.cloneDeep(info.details);
    details.splice(index, 1);
    this.info.details = details;
    this.calculateTotal();
  }

  async generatePdf(jobId) {
    const { info } = this.toJS();
    try {
      const body = this.toSaveData(info);
      const res = await http.post(`${config.api.sims}/v1/pdf/quotation?id=${jobId}`, body);
      if (res && res.data) {
        return res.data;
      }
    } catch (error) {
      throw new Error(error);
    }
  }

  parseFloatDetails(details) {
    for (let i = 0; i < details.length; i++) {
      const detail = details[i];
      if (typeof detail.qty !== 'number') detail.qty = parseFloat(detail.qty);
      if (typeof detail.price !== 'number') detail.price = parseFloat(detail.price);
      if (typeof detail.discount !== 'number') detail.discount = parseFloat(detail.discount);
      if (typeof detail.amount !== 'number') detail.amount = parseFloat(detail.amount);
      if (typeof detail.contractor_cost !== 'number') detail.contractor_cost = parseFloat(detail.contractor_cost || 0);
      if (typeof detail.contractor_discount !== 'number') detail.contractor_discount = parseFloat(detail.contractor_discount || 0);
      if (typeof detail.contractor_amount !== 'number') detail.contractor_amount = parseFloat(detail.contractor_amount || 0);
    }
  }

  parseFloatPeriods(periods) {
    for (let i = 0; i < periods.length; i++) {
      const period = periods[i];
      if (typeof period.amount !== 'number') period.amount = parseFloat(period.amount || 0);
    }
  }

  toContractorInfo(quotationItems) {
    if (quotationItems && quotationItems.length) {
      const result = quotationItems.reduce((acc, cur) => {
        return {
          totalContractorDiscount: cur.contractor_discount ? acc.totalContractorDiscount + parseFloat(cur.contractor_discount) : acc.totalContractorDiscount,
          totalContractorCost: cur.contractor_cost ? acc.totalContractorCost + (parseFloat(cur.qty || '1') * parseFloat(cur.contractor_cost || 0)) : acc.totalContractorCost,
        };
      }, {
        totalContractorDiscount: 0.00,
        totalContractorCost: 0.00,
      });
      const subTotal = result.totalContractorCost;
      const discountTotal = result.totalContractorDiscount;
      const netTotal = subTotal - discountTotal;
      const netExcludeVat = parseFloat((netTotal / 1.07).toFixed(2));
      const vat = parseFloat((netTotal - netExcludeVat).toFixed(2));
      const grandTotal = netTotal;
      return {
        subTotal,
        discountTotal,
        netTotal,
        netExcludeVat,
        vat,
        grandTotal,
      };
    }
    return {
      subTotal: 0.00,
      discountTotal: 0.00,
      netTotal: 0.00,
      netExcludeVat: 0.00,
      vat: 0.00,
      grandTotal: 0.00,
    };
  }

  toQuotationInfo(job, setQuotaionItems = true, quotationFormStates={}) {
    if (job) {
      const customerTax = job.customer_tax;
      if (customerTax) {
        const { address } = customerTax;
        this.info.type = customerTax.type;
        this.info.name = customerTax.name;
        this.info.taxId = customerTax.tax_id;
        this.info.branch = customerTax.branch;
        this.info.phone = customerTax.phone;
        this.info.address.no = address.no;
        this.info.address.province.code = address.province.code;
        this.info.address.province.name = address.province.name;
        this.info.address.district.code = address.district.code;
        this.info.address.district.name = address.district.name;
        this.info.address.subDistrict.code = address.sub_district.code;
        this.info.address.subDistrict.name = address.sub_district.name;
        this.info.address.postcode = address.postcode;
      } else {
        const { address_info: addressInfo } = job;
        this.info.type = 'individual';
        this.info.name = `${job.customer_firstname} ${job.customer_lastname}`;
        this.info.taxId = '';
        this.info.branch = '';
        this.info.phone = job.customer_phone;
        this.info.address.no = job.customer_address;
        this.info.address.province.code = addressInfo.province.code;
        this.info.address.province.name = addressInfo.province.name;
        this.info.address.district.code = addressInfo.district.code;
        this.info.address.district.name = addressInfo.district.name;
        this.info.address.subDistrict.code = addressInfo.sub_district.code;
        this.info.address.subDistrict.name = addressInfo.sub_district.name;
        this.info.address.postcode = addressInfo.post_code;
      }

      let quotation = (job.quotation && job.quotation.length) ? job.quotation[job.quotation.length - 1] : job.quotation;
      let selectedQuotation = {};
      if (quotation && setQuotaionItems) {
        const subQuotation = this.toJS().quotationInfo.sub_quotation || [];
        const draftQuotation = this.toJS().quotationInfo.draft_quotation || [];
        const isInstallment = subQuotation && subQuotation.length > 0;
        selectedQuotation = quotationFormStates.draftNo &&
          draftQuotation &&
          draftQuotation.length>0 && 
          !isInstallment ? _.find(draftQuotation, draft=> draft.draft_no === quotationFormStates.draftNo) : quotation;
        if (_.get(job, 'quotation.length') > 0) {
          const jobByQuotationNo = job.quotation.find(q => q.no === quotationFormStates.quotationNo);
          if (jobByQuotationNo) {
            quotation = jobByQuotationNo;
          }
        }
        else if (_.get(selectedQuotation, 'is_delete') === false) {
          // set data in order to edit quotation
          quotation = selectedQuotation;
        }
        else if (quotation.length === 0 && selectedQuotation.length === 0 && !isInstallment) {
          // set data in order to create more quotation
          quotation = draftQuotation.length > 0 ? draftQuotation[draftQuotation.length - 1] : quotation;
        }
        if (quotation.items && quotation.items.length) {
          const depositItem = quotation.items.filter(d => d.is_deposit);
          const quotationItem = quotation.items.filter(d => !d.is_deposit).map(value => {
            if(job.sale_model === 'Retail') {
              value.wh_flag = value.wh_flag === undefined ? true : value.wh_flag;
            }
            return value;
          });
          this.info.details = quotationItem;
          if(job.quotation && job.quotation.length > 1) {
            this.info.depositDetails = depositItem && depositItem.length ? depositItem : [this.createDefaultDepositDetail()];
          } else {
            this.info.depositDetails = depositItem && depositItem.length ? depositItem : job.survey_discount_items && job.survey_discount_items.length ? this.createSurveyDiscountDetail(job.survey_discount_items) : [this.createDefaultDepositDetail()];
          }
        } else {
          this.info.details = [this.createDefaultDetail()];
          if(job.quotation && job.quotation.length > 1) {
            this.info.depositDetails = [this.createDefaultDepositDetail()];
          } else {
            this.info.depositDetails = job.survey_discount_items && job.survey_discount_items.length ? this.createSurveyDiscountDetail(job.survey_discount_items) : [this.createDefaultDepositDetail()];
          }
        }

        this.info.isContactorQuotation = _.get(quotation, 'is_contractor_quotation', false);
        this.info.subTotal = quotation.sub_total || 0.00;
        this.info.discountTotal = quotation.discount_total || 0.00;
        this.info.netTotal = quotation.net_total || 0.00;
        this.info.vat = quotation.vat || 0.00;
        this.info.vatRate = quotation.vat_rate || vatRate;
        this.info.netExcludeVat = quotation.net_exclude_vat || 0.00;
        this.info.discountCoupon = quotation.discount_coupon || 0.00;
        this.info.grandTotal = quotation.grand_total || 0.00;
        this.info.remark = quotation.remark || '';
        this.info.contractorInfo = this.toContractorInfo(quotation.items);
        this.info.periods = quotation && quotation.length ? quotation[quotation.length - 1].periods : [];
        if (_.get(job, 'quotation.length') > 1) {
          this.info.isPaid = !!(quotation.periods.find(i => i.status === 'paid'));
        } else {
          this.info.isPaid = !!(this.info.periods.find(i => i.status === 'paid')) ||
            !!(job.payments && job.payments.payment_channel === 'paid_by_admin');
        }
        this.info.issueDate = quotation.issue_date || moment();
        this.info.expiredDate = quotation.expired_date || moment().add(30, 'days');
      // START dangerous zone
      if ((this.info.periods && this.info.periods.length)) {
        this.oldPeriods = this.info.periods.length;
        this.selectPeriods = this.info.periods.length;
      } 
      if (quotationFormStates.isNewDraft) {
        this.calculateTotal();
        this.saveInfo('periods', [{ amount: this.info.grandTotal, status: 'pending', ref: '' }]);
      }
      else if (_.get(quotation, 'periods.length') > 0) {
        this.saveInfo('periods', quotation.periods);
      }
      // END dangerous zone
      } else {
        this.info.isContactorQuotation = false;
        this.info.details = [this.createDefaultDetail()];
        this.info.depositDetails = [this.createDefaultDepositDetail()];
        this.saveInfo('periods', [{ amount: 0, status: 'pending', ref: '' }]);
      }

      this.info.requireWh = job.require_wh;
      this.info.quotationDetailFiles = job.quotation_detail_files ? job.quotation_detail_files : [];
      this.calculateTotal();
    } else {
      this.info = this.createDefaultInfo();
    }
  }

  toSaveData(info, sendMail) {
    this.parseFloatDetails(info.details);
    this.parseFloatPeriods(info.periods);
    return {
      send_mail: sendMail,
      customer_tax: {
        type: info.type,
        name: info.name,
        tax_id: info.taxId,
        branch: info.branch,
        phone: info.phone,
        address: {
          no: info.address.no,
          province: {
            code: info.address.province.code,
            name: info.address.province.name,
          },
          district: {
            code: info.address.district.code,
            name: info.address.district.name,
          },
          sub_district: {
            code: info.address.subDistrict.code,
            name: info.address.subDistrict.name,
          },
          postcode: info.address.postcode,
        },
      },
      quotation: [{
        items: info.details,
        sub_total: info.subTotal,
        discount_total: info.discountTotal,
        net_total: info.netTotal,
        vat: info.vat,
        vat_rate: info.vatRate,
        net_exclude_vat: info.netExcludeVat,
        discount_coupon: info.discountCoupon,
        grand_total: info.grandTotal,
        remark: info.remark,
        periods: info.periods,
        issue_date: datetime.SendToApiWithTime(info.issueDate),
        expired_date: datetime.SendToApi(info.expiredDate),
        is_contractor_quotation: _.get(info, 'isContactorQuotation', false),
      }],
      requireWh: info.requireWh,
      quotation_detail_files: info.quotationDetailFiles,
    };
  }

  async loadQuotationByJobId(jobId, quotationFormStates={}) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const res = await http.get(`${config.api.sims}/v1/jobs/${jobId}/quotation/detail`);
      this.isLoading = false;
      if (res && res.data && res.data.data) {
        const { data } = res.data;
        this.refCode = data.ref_code;
        this.typeOfJob = data.type_of_job;
        this.saleModel = data.sale_model;
        this.oldValue = (data.quotation &&
          data.quotation.length) ? data.quotation[data.quotation.length - 1].periods : [];
        this.toQuotationInfo(data, true, quotationFormStates);
      } else {
        throw new Error('โหลดข้อมูลล้มเหลว');
      }
    } catch (error) {
      this.isLoading = false;
      throw new Error(error);
    }
  }

  async saveQuotation(jobId, sendMail = false, isCreate = false, user = "Admin User") {
    if (this.isSubmitting) return;
    this.isSubmitting = true;

    const { info } = this.toJS();
    try {
      const body = this.toSaveData(info, sendMail);
      if (isCreate) {
        body['is_create'] = true;
      }
      body['user'] = user;
      const res = await http.put(`${config.api.sims}/v1/jobs/${jobId}/quotation`, body);
      this.isSubmitting = false;
      if (res && res.data) {
        return res.data
      }
    } catch (error) {
      this.isSubmitting = false;
      throw new Error(error);
    }
  }

  async initialNewQuotationInfo(jobId) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const res = await http.get(`${config.api.sims}/v1/jobs/${jobId}/quotation/detail`);
      this.isLoading = false;
      if (res && res.data && res.data.data) {
        const { data } = res.data;
        this.refCode = data.ref_code;
        this.typeOfJob = data.type_of_job;
        this.saleModel = data.sale_model;
        // this.oldValue = data.quotation[data.quotation.length - 1].periods;
        this.toQuotationInfo(data, false);
      } else {
        throw new Error('โหลดข้อมูลล้มเหลว');
      }
    } catch (error) {
      this.isLoading = false;
      throw new Error(error);
    }
  }

  async getOptionQuotation (jobCode) {
    this.isLoading = true;
    try {
      const res = await http.get(`${config.api.sims}/v1/quotations/getQuotation/${jobCode}`);
      this.isLoading = false;
      if(res && res.data && res.data.data && res.status === 200){
        const { data } = res.data;
        this.quotationInfo = data
        return data
      } else {
        return undefined
      }
    } catch (error) {
      this.isLoading = false;
      throw new Error(error);
    }
  }

  async getPaginatedDraftQuotations(query = { limit: 10, page: 1 }) {
    query = pickBy(query, (value) => !!value)
    const qstring = qs.stringify(pick(query, ['limit', 'page', 'draftQuotationNo', 'visible', 'jobCode']))

    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const response = await http.get(`${config.api.sims}/v1/quotations/pagination?${qstring}`);
      if (response.status === 200 && response.data) {
        this.items = response.data.items;
        return Promise.resolve(response.data)
      }

    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async updateDraftQuotationPublic(payload) {
    this.isLoading = true;
    try {
      const res = await http.put(`${config.api.sims}/v1/quotations/draft/updateQuotationPublic`, payload);
      this.isLoading = false;
      const { data } = res;
      this.quotationInfo = data;
      return data;
    } catch (error) {
      this.isLoading = false;
      throw new Error(error);
    }
  }

  toUpdateObjectDraftQuotationByNo(draftNo='') {
    if(draftNo){
      const info = this.toJS().info;
      const quotationInfo = this.toJS().quotationInfo;
      const draftQuotation = quotationInfo.draft_quotation || [];
      const indexExists = _.findIndex(draftQuotation, draft=>draft.draft_no === draftNo)
      if ( indexExists<0 ) return;
      draftQuotation[indexExists].items= info.details
      draftQuotation[indexExists].sub_total= info.subTotal
      draftQuotation[indexExists].discount_total= info.discountTotal
      draftQuotation[indexExists].net_total= info.netTotal
      draftQuotation[indexExists].vat= info.vat
      draftQuotation[indexExists].vat_rate= info.vatRate
      draftQuotation[indexExists].net_exclude_vat= info.netExcludeVat
      draftQuotation[indexExists].discount_coupon= info.discountCoupon
      draftQuotation[indexExists].grand_total= info.grandTotal
      draftQuotation[indexExists].remark= info.remark
      draftQuotation[indexExists].periods= info.periods
      draftQuotation[indexExists].issue_date= datetime.SendToApiWithTime(info.issueDate)
      draftQuotation[indexExists].expired_date= datetime.SendToApi(info.expiredDate)
      return [draftQuotation[indexExists]];
    }
  }

  async updateDraft() {
    this.isLoading = true;
    try {
      const payload = this.toJS().quotationInfo
      const jobInfo = JobStore.toJS().info;
      payload.installationStartDate = jobInfo.installationStartDate
        ? datetime.SendToApi(jobInfo.installationStartDate)
        : undefined;
      payload.installationEndDate = jobInfo.installationEndDate
        ? datetime.SendToApi(jobInfo.installationEndDate)
        : undefined;
      payload.contractorId = jobInfo.contractorId;

      const res = await http.put(`${config.api.sims}/v1/quotations/draft/updateDraft`, payload);
      this.isLoading = false;
      const { data } = res;
      this.quotationInfo = data;
      return data;
    } catch (error) {
      this.isLoading = false;
      throw new Error(error);
    }
  }

  async createAndUpdateQuotationDraft(payload){
    this.isLoading = true;
    try {
      const { info } = this.toJS()
      let body =  this.toSaveData(info, payload.send_mail)
      if(payload.draftNo){
        body.draftNo = String(payload.draftNo);
        body.is_edit_draft = payload.isEditDraft;
        body.quotation = this.toUpdateObjectDraftQuotationByNo(payload.draftNo);
      }
      body.job_code = payload.jobCode;
      body.job_id = payload.jobId;
      body.user = payload.user;
 
      const res = await http.post(`${config.api.sims}/v1/quotations/draft/createAndUpdateQuotationDraft`, body);
      if(res.status!==200) throw new Error('สร้างใบเสนอราคาไม่สำเร็จ')
      return res.data.jobI;
    } catch (error) {
      this.isLoading = false;
      throw new Error(error);
    }
  }

  async deleteDraftQuotation(payload){
    this.isLoading = true;
    try {
       const res = await http.delete(`${config.api.sims}/v1/quotations/draft/deleteQuotationDraft`, payload);
       this.isLoading = false;
       const { data } = res;
       this.quotationInfo = data;
       return data;
    } catch (error) {
      this.isLoading = false;
      throw new Error(error);
    }
  }

  async addInstallmentsCreateJobs(dataInstallmentsQuotationInfo) {
    if (this.isSubmitting) return;
    this.isSubmitting = true;

    try {
      const res = await http.post(`${config.api.sims}/v1/quotations/createQuotation`, dataInstallmentsQuotationInfo);
      this.isSubmitting = false;
      if (res && res.data && res.data.data) {
        const { data } = res.data;
        this.isSubmitting = false;
        return true
      } else {
        return null
      }
    } catch (error) {
      this.isSubmitting = false;
      throw new Error(error);
    }
  }

  isWhFlag() {
    return this.saleModel === "Retail" ? true : false;
  }
  
  async logQuotation(payload) {
    try {
      const response = await http.post(`${config.api.sims}/v1/quotations/logQuotation`, payload);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
}

export default new QuotationStore();
