import * as _ from 'lodash';
import moment from 'moment';
import renderHtml from 'react-render-html';
import config from '../config';
import { HolidayList } from '../utils/data';
import datetime from '../utils/datetime';
import http from '../utils/http';
import BaseStore from './BaseStore';
import AuthStore from './AuthStore';
import ContractorStore from './ContractorStore';
import JobStore from './JobStore';

export class CalendarStore extends BaseStore {
  constructor() {
    super();
    this.observable({
      isVacationSubmiting: false,
      isCalendarLoading: false,
      calendarFilter: undefined,
      holidays: HolidayList,
      vacation: [],
      jobs: [],
      info: this.initInfo(),
    });
  }

  setCalendarFilter(filter) {
    this.calendarFilter = filter;
  }

  initInfo() {
    return {
      startDate: undefined,
      endDate: undefined,
      typeOfJob: '',
      unavailable: 0,
      startedAt: new Date(),
    };
  }

  clearInfo() {
    this.info = this.initInfo();
  }

  saveInfo(key, value) {
    const info = this.toJS().info;
    info[key] = value;
    this.info = info;
  }

  saveMultiInfo(data) {
    let keys = Object.keys(data);
    const info = this.toJS().info;
    _.forEach(keys, k => {
      info[k] = data[k];
    });
    this.info = info;
  }

  getVacationText(id, isHtml, isFullJob, isAdmin, createdAt) {
    // let contractorInfo = AuthStore.getContractor();
    // let preText = 'ไม่มี<br />ช่างพร้อม<br />';
    // let midText = 'ให้บริการ<br />';
    // if (!isAdmin) {
    //   preText = 'ไม่พร้อม<br />';
    //   if (isFullJob) {
    //     preText = 'คิวเต็ม<br />';
    //     midText = '';
    //   }
    // }
    const isSuperAdmin = AuthStore.isSuperAdmin();
    createdAt = createdAt && isSuperAdmin ? moment(createdAt).utcOffset(7).format('DD/MM/YYYY HH:mm:ss') + ' น.' : '';

    const adminTextAllDay = 'ไม่มี<br />ช่างพร้อม<br />ให้บริการ<br />';
    const adminTextMorning = 'ไม่มี<br />ช่างพร้อม<br />ให้บริการ<br />(เช้า)';
    const adminTextAfternoon = 'ไม่มี<br />ช่างพร้อม<br />ให้บริการ<br />(บ่าย)';
    const contractorTextAllDay = `ไม่พร้อม<br />ให้บริการ<br />${createdAt}`;
    const contractorTextMorning = `ไม่พร้อม<br />ให้บริการ<br />(เช้า)<br />${createdAt}`;
    const contractorTextAfternoon = `ไม่พร้อม<br />ให้บริการ<br />(บ่าย)<br />${createdAt}`;
    const contractorFullJobTextAllDay = 'คิวเต็ม<br />ทั้งวัน<br />';
    const contractorFullJobTextMorning = 'คิวเช้า<br />เต็ม<br /><p>';
    const contractorFullJobTextAfternoon = 'คิวบ่าย<br />เต็ม<br /><p>';
    let str = '';
    let short = '';
    switch (id) {
      case 0:
        // str = isAdmin ? `${preText}${midText}` : `${preText} ทั้งวัน`;
        str = isAdmin ? adminTextAllDay : (isFullJob ? contractorFullJobTextAllDay : contractorTextAllDay);
        short = 'ทั้ง<br />วัน';
        break;
      case 2:
        // str = isAdmin ? `${preText}${midText} (บ่าย)` : 'คิวเช้า<br />เต็ม ';
        str = isAdmin ? adminTextAfternoon : (isFullJob ? contractorFullJobTextAfternoon : contractorTextAfternoon);
        short = 'บ่าย';
        break;
      case 1:
        // str = isAdmin ? `${preText}${midText} (เช้า)` : 'คิวบ่าย<br />เต็ม ';
        str = isAdmin ? adminTextMorning : (isFullJob ? contractorFullJobTextMorning : contractorTextMorning);
        short = 'เช้า';
        break;
      default:
        break;
    }

    if (isHtml) {
      let mobile = `
      <div className="event-mobile">
        <i className="fa fa-times"></i> <br /> ${short}
      </div>`;
      return renderHtml(`${mobile}<div className="event-non-mobile">${str}</div>`);
    } else {
      return str.replace(/<br \/>/g, '');
    }
  }

  groupVacationByEventId = (originalVacations) => {
    const eventIds = _.uniq(_.map(originalVacations, 'event_id'));
    const jobTypes = _.uniq(_.map(originalVacations, 'type_of_job'));
    let vacations = [];

    for (const eventId of eventIds) {
      for (const jobType of jobTypes) {
        let toPushVacations = [];
        if (_.isUndefined(eventId)) {
          toPushVacations = _.filter(originalVacations, ({ event_id, type_of_job }) => _.isUndefined(event_id) && type_of_job === jobType);
        } else {
          const eventVacations = _.filter(originalVacations, ({ event_id, type_of_job }) => event_id === eventId && type_of_job === jobType);
          const sortedEventVacations = _.sortBy(eventVacations, 'start_date');
          toPushVacations = sortedEventVacations.reduce((prevVal, currentVal) => {
            const lastVacation = _.last(prevVal);
            if (lastVacation && moment(lastVacation.end_date).isSame(moment(currentVal.start_date).subtract(1, 'days'), 'day')) {
              prevVal[prevVal.length - 1].end_date = currentVal.start_date;
            } else {
              prevVal.push(currentVal);
            }
            return prevVal;
          }, []);
        }
        vacations = [...vacations, ...toPushVacations];
      }
    }

    return vacations;
  };

  getVacationEvent(vacationList, isAdmin) {
    return this.groupVacationByEventId(vacationList).map(item => {
      let startDate = moment(item.start_date);
      let endDate = moment(item.end_date);
      return {
        id: item['_id'],
        isVacation: true,
        unavailable: item.unavailable,
        type_of_job: item.type_of_job,
        title: this.getVacationText(item.unavailable, true, item.fullJob, isAdmin, item.created_at),
        allDay: item.unavailable === 0,
        start: startDate,
        startStr: startDate.format('YYYY-MM-DD'),
        end: endDate,
        endStr: endDate.format('YYYY-MM-DD'),
        fullJob: item.fullJob,
      };
    });
  }

  async getVacation(options) {
    if (this.isCalendarLoading) return;

    try {
      this.isCalendarLoading = true;
      const data = await ContractorStore.getVacation(options);
      if (Array.isArray(data) && data.length) {
        const vacationInfo = data[0];
        this.info = {
          startDate: moment(vacationInfo.start_date),
          endDate: moment(vacationInfo.end_date),
          typeOfJob: vacationInfo.type_of_job,
          unavailable: vacationInfo.unavailable,
        };
      }

      this.isCalendarLoading = false;
    } catch (error) {
      this.isCalendarLoading = false;
      throw error;
    }
  }

  async getJobEvent(jobList, isAdmin, typeOfWork = null) {
    let jobEvent = await Promise.all(jobList.map(async (job) => {
      const availableValue = +(job.available || 0);
      let time = '';
      if (config.svCardTypeId && config.svCardTypeId.includes(job.type_of_work.values().next().value) && job.time_label) {
        // if (job.time_label) {
        time = job.time_label;
        // } else {
        //   const result = typeOfWork.items.filter(a => a.value === job.type_of_work.values().next().value);
        //   const resultTime = result.values().next().value.time.filter(item => parseInt(item.value, 10) === parseInt(availableValue, 10));
        //   time = resultTime.values().next().value.label;
        // }
      } else {
        if (availableValue === 1) {
          time = 'ช่วงเช้า';
        } else if (availableValue === 2) {
          time = 'ช่วงบ่าย';
        } else {
          time = 'ทั้งวัน';
        }
      }
      let detail = '';
      let contractorInfo;
      if (isAdmin) {
        const contractors = ContractorStore.list;
        contractorInfo = contractors.find(c => c._id === job.contractor_id);
        if (contractorInfo) {
          detail = `${time}<br />ทีม${contractorInfo.team_name}`;
          detail += `<br />${job.type_of_job}-${job.customer_firstname} ${job.customer_lastname}`;
        } else {
          detail = `${time}<br />${job.customer_firstname} ${job.customer_lastname}`;
          detail += `<br />โทร. ${job.customer_phone}`;
        }
      } else {
        contractorInfo = AuthStore.getContractor();
        detail = `${time}<br />${job.customer_firstname} ${job.customer_lastname}<br />โทร. ${job.customer_phone}`;
      }

      return {
        isJob: true,
        isAssigned: !!job.contractor_id && job.is_paid,
        contractorId: job.contractor_id,
        id: job._id,
        desc: '',
        title: renderHtml(detail),
        available: job.available,
        start: moment(job.start_date).toDate(),
        end: moment(job.end_date).toDate(),
        typeOfJob: job.type_of_job,
        status: job.status,
        customer_email: job.customer_email,
        isContractorJob: job.is_contractor_job || false,
        isPaid: job.is_paid,
      };
    }));
    return jobEvent;
  }

  async getContractorCalendar(options, typeOfWork = null) {
    if (this.isCalendarLoading) return;

    if (options && options.contractorId) {
      try {
        this.isCalendarLoading = true;
        let vacationList = [];
        const data = await Promise.all([
          this.findContractor(options),
          JobStore.getJobs(options),
          ContractorStore.getVacation(options)]);
        const contractorData = data[0] || [];
        const contractorVacation = _.map(contractorData, 'vacations');
        const josAsvacationData = (contractorVacation[0] || []).filter(v => !v._id);
        const vacationData = data[2];
        vacationList = await this.getJobAsVacation([josAsvacationData], 1);
        vacationList = this.getVacationEvent(vacationList, false);
        vacationList = _.concat(vacationList, this.getVacationEvent(vacationData, false));
        this.vacation = vacationList;

        let jobs = await this.getJobEvent(data[1], false, typeOfWork);
        this.jobs = jobs;

        this.isCalendarLoading = false;
        options.isAdmin = false;
      } catch (error) {
        this.isCalendarLoading = false;
        throw error;
      }
    }
  }

  async findContractor(options) {
    const contractorStore = ContractorStore.toJS().info;
    let contractorList = contractorStore.list;
    if (options.contractorId && options.filterContractorsOnly) {
      if (!Array.isArray(contractorList) || !contractorList.length) {
        contractorList = await ContractorStore.getContractorAdminCalendar({
          ...options,
          needVacation: 'y',
        });
      }
      return contractorList.filter(c => c._id === options.contractorId);
    } else {
      contractorList = await ContractorStore.getContractorAdminCalendar({
        ...options,
        needVacation: 'y',
      });
      if (options.contractorId) {
        return contractorList.filter(c => c._id === options.contractorId);
      } else {
        if (options.aCode && contractorList) {
          return contractorList.filter(c => c.area_of_work.filter(
            d => {
              return d.district_id.filter(id => { return id === options.aCode; }).length > 0;
            }).length > 0);
        } else {
          return contractorList;
        }
      }
    }
  };

  async getAdminCalendar(options, typeOfWork = null) {
    if (this.isCalendarLoading) return;
    try {
      this.isCalendarLoading = true;

      let vacationList = [];
      // const data = await Promise.all([
      //   this.findContractor(options),
      //   JobStore.getJobs(options)
      // ]
      // );
      // const data = [];
      const contractorData = await this.findContractor(options) || [];

      // const contractorData = data[0] || [];
      const vacationData = _.map(contractorData, 'vacations');
      const totalContractor = contractorData.length;
      vacationList = await this.getJobAsVacation(vacationData, totalContractor);
      vacationList = this.getVacationEvent(vacationList, true);
      this.vacation = vacationList;

      // Calculate Job
      // const jobData = data[1] || [];
      let jobData = [];
      let jobsData = [];
      if (!options.not_search_job) {
        jobData = await JobStore.getJobs(options) || [];
        jobsData = await this.getJobEvent(jobData, options.isAdmin, typeOfWork);
      }

      this.isCalendarLoading = false;
      return jobsData;
    } catch (error) {
      this.isCalendarLoading = false;
      throw error;
    }
  }

  async getJobAsVacation(jobData, totalContractor) {
    let vacationData = jobData;
    let vacationList = [];

    vacationData = _.concat(...vacationData);
    let vacationDateRange = [];
    _.forEach(vacationData, vacation => {
      let dateRange = datetime.GetBetweenDates(vacation.start_date, vacation.end_date);
      vacationDateRange = _.concat(vacationDateRange, dateRange.map(date => {
        return {
          _id: vacation._id,
          date,
          format: date.format('YYYYMMDD'),
          jobType: vacation.type_of_job,
          unavailable: vacation.unavailable,
        };
      }));
    });
    vacationDateRange = _.orderBy(vacationDateRange, 'format');
    vacationDateRange = _.groupBy(vacationDateRange, 'format');
    const vacationDateRangeKeys = Object.keys(vacationDateRange);
    vacationDateRange = vacationDateRangeKeys
      .filter(key => vacationDateRange[key].length >= totalContractor)
      .map(key => vacationDateRange[key]);

    _.forEach(vacationDateRange, vacation => {
      const unavailableAll = vacation.filter(v => v.unavailable === 0);
      const totalAll = unavailableAll.length;
      const unavailableMorning = vacation.filter(v => v.unavailable === 1);
      const totalMorning = unavailableMorning.length;
      const unavailableAfternoon = vacation.filter(v => v.unavailable === 2);
      const totalAfternoon = unavailableAfternoon.length;
      let vacationObj;

      if (totalAll >= totalContractor) {
        vacationObj = unavailableAll[0];
      } else {
        const total = totalContractor - totalAll;

        if (totalMorning >= total && totalAfternoon >= total) {
          vacationObj = unavailableMorning[0] || unavailableAfternoon[0];
          if (vacationObj) {
            vacationObj.unavailable = 0;
          }
        } else if (totalMorning >= total) {
          vacationObj = unavailableMorning[0];
        } else if (totalAfternoon >= total) {
          vacationObj = unavailableAfternoon[0];
        }
      }

      if (vacationObj) {
        let isPush = true;
        if (vacationList.length) {
          const previousVacation = _.takeRight(vacationList)[0];
          if (previousVacation.unavailable === vacationObj.unavailable) {
            if (datetime.isEqual(moment(previousVacation.end_date).add(1, 'days'), vacationObj.date)) {
              isPush = false;
            }
          }
        }

        if (isPush) {
          vacationList.push({
            _id: vacationObj._id,
            start_date: vacationObj.date,
            end_date: vacationObj.date,
            unavailable: vacationObj.unavailable,
            fullJob: !vacationObj._id,
          });
        } else {
          vacationList[vacationList.length - 1].end_date = vacationObj.date;
        }
      }
    });
    return vacationList;
  }

  /**
   * @deprecated
   */
  async submitVacation(vacationId) {
    if (this.isVacationSubmiting) return;
    this.isVacationSubmiting = true;

    try {
      let contractor = AuthStore.getContractor();
      if (!contractor) throw new Error('ข้อมูลผู้ใช้ระบบไม่ถูกต้อง');

      const self = this.toJS();
      const info = self.info;
      const startDate = datetime.SendToApi(info.startDate);
      const endDate = datetime.SendToApi(info.endDate);

      let result;
      if (vacationId) {
        result = await http.put(`${config.api.sims}/v1/contractors/${contractor._id}/vacations/${vacationId}`, {
          unavailable: info.unavailable,
          type_of_job: info.typeOfJob,
          source: 'sims-web',
        });
        this.isVacationSubmiting = false;
        return vacationId;
      } else {
        result = await http.post(`${config.api.sims}/v1/contractors/${contractor._id}/vacations`, {
          start_date: startDate,
          end_date: endDate,
          unavailable: info.unavailable,
          type_of_job: info.typeOfJob,
          source: 'sims-web',
        });
        if (result && result.data && result.data.data) {
          this.isVacationSubmiting = false;
          return result.data.data[0]._id;
        }
      }

      this.isVacationSubmiting = false;
    } catch (error) {
      this.isVacationSubmiting = false;
      throw error;
    }
  }

  createVacation = async ({ contractorId, startDate, endDate, time, jobType }) => {
    try {
      if (this.isVacationSubmiting) {
        return;
      }
      this.isVacationSubmiting = true;

      const { data, status } = await http.post(`${config.api.sims}/v1/contractors/${contractorId}/vacations`, {
        start_date: datetime.SendToApi(startDate),
        end_date: datetime.SendToApi(endDate),
        unavailable: time,
        type_of_job: jobType,
        source: 'sims-web',
      });

      if (status !== 200) {
        throw new Error(data.message);
      }
    } catch (error) {
      throw error;
    } finally {
      this.isVacationSubmiting = false;
    }
  };

  updateVacation = async ({ contractorId, vacationIds, newTime, newJobType }) => {
    try {
      if (this.isVacationSubmiting) {
        return;
      }
      this.isVacationSubmiting = true;

      const { data, status } = await http.put(`${config.api.sims}/v1/contractors/${contractorId}/vacations`, {
        vacation_ids: vacationIds,
        new_unavailable: newTime,
        new_job_type: newJobType,
      });

      if (status !== 200) {
        throw new Error(data.message);
      }
    } catch (error) {
      throw error;
    } finally {
      this.isVacationSubmiting = false;
    }
  };

  deleteVacation = async ({ contractorId, vacationIds }) => {
    try {
      if (this.isVacationSubmiting) {
        return;
      }
      this.isVacationSubmiting = true;

      const { data, status } = await http.delete(`${config.api.sims}/v1/contractors/${contractorId}/vacations`, {
        vacation_ids: vacationIds,
      });

      if (status !== 200) {
        throw new Error(data.message);
      }
    } catch (error) {
      throw error;
    } finally {
      this.isVacationSubmiting = false;
    }
  };

  validateVacation = async ({ contractorId, startDate, endDate, jobType, excludeIds }) => {
    try {
      if (this.isVacationSubmiting) {
        return;
      }
      this.isVacationSubmiting = true;

      const { status } = await http.post(`${config.api.sims}/v1/contractors/${contractorId}/validate-vacation`, {
        start_date: moment(startDate).startOf('day').toISOString(),
        end_date: moment(endDate).endOf('day').toISOString(),
        job_type: jobType,
        exclude_ids: excludeIds,
      });

      return { status };
    } catch (error) {
      throw error;
    } finally {
      this.isVacationSubmiting = false;
    }
  };

  acknowledgeVacation = async ({ contractorId, startDate, endDate, unavailable, jobType }) => {
    try {
      if (this.isVacationSubmiting) {
        return;
      }
      this.isVacationSubmiting = true;

      const { data, status } = await http.post(`${config.api.sims}/v1/contractors/${contractorId}/acknowledge-vacation`, {
        start_date: moment(startDate).startOf('day').toISOString(),
        end_date: moment(endDate).endOf('day').toISOString(),
        unavailable,
        job_type: jobType,
      });

      if (status !== 200) {
        throw new Error(data.message);
      }
    } catch (error) {
      throw error;
    } finally {
      this.isVacationSubmiting = false;
    }
  };
}

export default new CalendarStore();
