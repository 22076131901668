import Axios from 'axios';
import BaseStore from './BaseStore';
import config from '../config';

export class SubContractorStore extends BaseStore {
  constructor() {
    super();
    this.observable({
      // for list page
      info: this.InitInfo(),
      // for edit page
      isEdit: false,
      selectedInfo: null,
      selectedInfoDraft: null,
      // common
      isLoading: true,
      pagination: {
        page: 1,
        page_range: 5,
        page_size: 10,
      },
      query: {
        q: '',
        main_contractor_id: '',
        status: '',
        criminal_status: '',
      },
      options: {
        contractor_team: [],
        status: [
          { value: '', label: 'ทั้งหมด' },
          { value: 'registered', label: 'รออนุมัติ' },
          { value: 'approved', label: 'อนุมัติ' },
          { value: 'rejected', label: 'ไม่อนุมัติ' },
          { value: 'waiting_delete', label: 'ร้องขอการลบบัญชี' },
          { value: 'waiting_edit_approve', label: 'รออนุมัติการแก้ไข' },
        ],
        criminal_status: [
          { value: '', label: 'ทั้งหมด' },
          { value: '0', label: 'มีผลตรวจแล้ว' },
          { value: '1', label: 'กำลังรอผลตรวจ' },
          { value: '2', label: 'ยังไม่มีผลตรวจ' },
        ],
        province: [],
        nationality: [
          { value: 'TH', label: 'ไทย' },
          { value: 'Other', label: 'อื่่นๆ' },
        ],
      },
      fileTypes: {
        profile_pic: 'profile_pic',
        id_card: 'id_card',
        criminal_record_check: 'criminal_record_check',
        electricity_certificate: 'electricity_certificate',
        other_certificate: 'other_certificate',
        company_cert: 'company_cert',
        vat: 'vat',
        book_bank: 'book_bank',
      },
    });
  }

  InitInfo() {
    return [];
  }

  clearQuery() {
    this.query = {
      q: '',
      main_contractor_id: '',
      status: '',
      criminal_status: '',
    };
  }

  clearState() {
    this.selectedInfo = null;
    this.selectedInfoDraft = null;
  }

  setSelectedInfo(key, data) {
    this.selectedInfo[key] = data;
  }

  onChangeAddressType(value) {
    this.selectedInfo.car_id_province = value;
  }

  addFile(file) {
    this.selectedInfo.file.push(file);
  }

  removeFile(files) {
    this.selectedInfo.file = files;
  }

  setQuery(key, value) {
    this.query[key] = value;
  }

  setPagination(key, value) {
    this.pagination[key] = value;
  }

  setOptions(key, value) {
    this.options[key] = value;
  }

  // API CALL
  async fetch() {
    try {
      this.isLoading = true;
      const response = await Axios.get(`${config.api.sims}/v1/subContractor/paginate`, {
        params: {
          ...this.query,
          ...this.pagination,
        },
      });
      this.info = response.data.data;
      this.pagination = response.data.pagination;
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async fetchById(id) {
    try {
      this.isLoading = true;
      const response = await Axios.get(`${config.api.sims}/v1/subContractor/${id}`);
      this.selectedInfo = response.data.data;

      // fetch draft data
      if (response.data.data.has_draft) {
        await this.findDraft(id);
      }

      return response.data.data;
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async update(id, data) {
    try {
      this.isLoading = true;
      const response = await Axios.put(`${config.api.sims}/v1/subContractor/${id}`, data);
      if (!response.data.data) {
        throw new Error('อัพเดทข้อมูลไม่สำเร็จ');
      }

      // force update info data
      if (this.info && this.info.length) {
        this.info = this.info.map((item) => {
          if (item._id === id) return response.data.data;
          return item;
        });
      }

      // force update selectedInfo data
      if (this.selectedInfo) {
        this.selectedInfo = response.data.data;
      }

      return true;
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async remove(id, payload) {
    try {
      console.log('payload', payload);
      this.isLoading = true;
      await Axios.delete(`${config.api.sims}/v1/subContractor/${id}`, { data: payload });
      this.info = this.info.filter((item) => item._id !== id);
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async findTeam(id) {
    try {
      if (!id) return;
      this.isLoading = true;
      const response = await Axios.get(`${config.api.sims}/v1/subContractor/team/${id}`);
      if (!response.data.data) {
        this.options.contractor_team = [];
      }

      const mainContractor = response.data.data.main_contractor;
      const subContractors = response.data.data.sub_contractor;

      const mergeData = []
      if (mainContractor) {
        mergeData.push(mainContractor)
      }

      if (subContractors && subContractors.length) {
        mergeData.push(...subContractors)
      }

      this.options.contractor_team = mergeData;
      return mergeData;
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async uploadFile(id, file, type) {
    try {
      this.isLoading = true;

      if (!file) {
        return;
      }

      const imageFormData = new FormData();
      let path = 'upload/file';
  
      if (type === 'profile_pic') {
        path = 'upload/picture-profile';
        imageFormData.append('profile_pic', file);
      } else if (type === 'signature') {
        path = 'upload/signature';
        imageFormData.append('signature', file);
      } else {
        imageFormData.append('files', file);
      }
    
      const imageUpload = await Axios.post(`${config.api.sims}/v1/contractors/${path}`, imageFormData, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'multipart/form-data',
        },
      });

      if (!imageUpload.data) {
        throw new Error('upload failed');
      }

      if (type === 'profile_pic') {
        this.selectedInfo.profile_pic = imageUpload.data.result.url;
      } else if (type === 'signature') {
        this.selectedInfo.signature = imageUpload.data.result.url;
      } else {
        this.selectedInfo.file = [
          ...this.selectedInfo.file,
          ...imageUpload.data.data.map((file) => ({
            name: file.name,
            path: file.pathFile,
            type: type,
            url: file.path,
            date: new Date(file.date),
          })),
        ];
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  // API CALL FOR DRAFT DATA
  async findDraft(subContractorId) {
    try {
      const response = await Axios.get(`${config.api.sims}/v1/subContractor/${subContractorId}/draft`);
      if (response.data.data) {
        this.selectedInfoDraft = response.data.data;
      }

      return response.data.data;
    } catch (error) {
      throw error;
    }
  }

  async approveOrRejectDraft(id, data) {
    try {
      const url = `${config.api.sims}/v1/subContractor/${id}/draft`;
      const response = await Axios.put(url, data);
      // force update selectedInfo data
      if (this.selectedInfo && response.data.data) {
        this.selectedInfo = response.data.data;
      }

      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
}

export default new SubContractorStore();
