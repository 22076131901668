import React from 'react';
import { NavLink } from 'react-router-dom';
import { isEmpty, isArray, orderBy } from 'lodash';
import { observer, inject } from 'mobx-react';
import sweetAlert from 'sweetalert2';

import Container from '../../layouts/Container';
import Notification from '../../components/common/Notification';
import Loader from '../../components/common/Loader';
import Pagination from '../../components/common/Pagination';
import EnText from '../../components/form/EnText';
import EnButton from '../../components/form/EnButton';
import EnTagSelect from '../../components/form/EnTagSelect';

import config from '../../config';

import 'moment/locale/th';
import moment from 'moment';
import { isRole, ROLE } from '../../utils/permission';

export class SubContractor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: isRole(ROLE.INSTALLER_MANAGEMENT),
    };
  }

  async componentDidMount() {
    try {
      //await this.props.subContractor.fetch();
      const url = new URL(window.location.href);
      url.search === ''? await this.props.subContractor.fetch(): await this.onSearch(true)
      await this.props.contractor.getAllWithFields({ is_deleted: false });

      const contractors = this.props.contractor.toJS().list;
      const ctr = orderBy(contractors, ['team_name']).map((con) => {
        return {
          label: con.team_name,
          value: con._id,
        };
      });
      this.props.subContractor.setOptions('contractor_team', ctr);
    } catch (error) {
      this.noti.error(error.message);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  // ACTIONS
  onSearch = async (hasPage) => {
    const url = new URL(window.location.href);
    this.props.subContractor.setQuery('q', url.searchParams.get('q'));
    this.props.subContractor.setQuery('main_contractor_id', url.searchParams.get('main_contractor_id'));
    this.props.subContractor.setQuery('status', url.searchParams.get('status'));
    this.props.subContractor.setQuery('criminal_status', url.searchParams.get('criminal_status'));
    hasPage === true ? this.props.subContractor.setPagination('page', localStorage.getItem('page')) : this.props.subContractor.setPagination('page', 1);
    await this.props.subContractor.fetch();
  };

  onChange = (value) => {
    this.props.subContractor.setQuery('q', value);
    const url = new URL(window.location.href);
    url.searchParams.set('q', value); 
    window.history.replaceState({}, '', url.toString());  
  };

  onSelect = (value, key) => {
    this.props.subContractor.setQuery(key, value);
    const url = new URL(window.location.href);
    url.searchParams.set(key, value); 
    window.history.replaceState({}, '', url.toString());  
  };

  onChangePage = async (value) => {
    this.props.subContractor.setPagination('page', value || localStorage.getItem('page'));
    localStorage.setItem('page', value);
    await this.props.subContractor.fetch();
  };

  onClick = async (item, key) => {
    if (key === 'edit') {
      return this.props.history.push(`${config.publicUrl}/superadmin/sub-contractors/${item._id}/edit`);
    }

    if (key === 'approved') {
      const swalResponse = await sweetAlert.fire({
        title: 'ยืนยันการอนุมัติ',
        text: 'คุณต้องการอนุมัติช่างคนนี้ใช่หรือไม่',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'อนุมัติ',
        cancelButtonText: 'ยกเลิก',
        cancelButtonColor: '#d33',
      });

      if (swalResponse && swalResponse.isConfirmed && swalResponse.value) {
        this.updateStatus(item._id, key);
      }
    }

    if (key === 'rejected') {
      const swalResponse = await sweetAlert.fire({
        title: 'ไม่อนุมัติช่าง',
        showCancelButton: true,
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        cancelButtonColor: '#d33',
        input: 'textarea',
        inputLabel: 'Message',
        inputPlaceholder: 'ระบุเหตุผล...',
        inputAttributes: {
          'aria-label': 'ระบุเหตุผล',
        },
        onRender: (dom) => {
          // if textarea is empty, disable confirm button
          const textarea = dom.querySelector('textarea');
          const confirmButton = dom.querySelector('.swal2-confirm');
          confirmButton.setAttribute('disabled', 'disabled');

          textarea.addEventListener('input', () => {
            if (textarea.value) {
              confirmButton.removeAttribute('disabled');
            } else {
              confirmButton.setAttribute('disabled', 'disabled');
            }
          });
        },
      });

      if (swalResponse && swalResponse.isConfirmed && swalResponse.value) {
        this.updateStatus(item._id, key, swalResponse.value);
      }
    }

    if (key === 'remove') {
      const swalResponse = await sweetAlert.fire({
        title: 'ลบช่าง',
        text: 'คุณต้องการลบช่างคนนี้ใช่หรือไม่',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ลบ',
        cancelButtonText: 'ยกเลิก',
        cancelButtonColor: '#d33',
      });

      if (swalResponse && swalResponse.isConfirmed && swalResponse.value) {
        this.updateStatus(item._id, key);
      }
    }
  };

  // API CALL
  updateStatus = async (id, type, reason) => {
    try {
      const user = this.props.auth.getUserInfo();

      if (!id) {
        throw new Error('ไม่พบข้อมูล');
      }

      if (type === 'approved') {
        await this.props.subContractor.update(id, { status: 'approved', user: user.email });
        this.noti.success('อนุมัติสำเร็จ');
      }

      if (type === 'rejected') {
        await this.props.subContractor.update(id, { status: 'rejected', user: user.email, reason });
        this.noti.success('ไม่อนุมัติสำเร็จ');
      }

      if (type === 'remove') {
        await this.props.subContractor.remove(id, { user: user.email });
        this.noti.success('ลบสำเร็จ');
      }
    } catch (error) {
      this.noti.error(error.message);
    }
  };

  renderPhoneFormat = (phone) => {
    if (!phone) return '';
    const phoneNo = phone.replace(/-/g, '');
    if (phoneNo.length === 10) {
      return `(${phoneNo.substr(0, 3)}-${phoneNo.substr(3, 3)}-${phoneNo.substr(6, 4)})`;
    } else {
      return phone;
    }
  };

  // TABLE RENDER
  renderTable = (subContractors, options) => {
    const { editMode } = this.state;

    const columns = [
      { key: 'name', title: 'ชื่อ-สกุล' },
      { key: 'team_name', title: 'ข้อมูลทีม' },
      { key: 'main_contractor', title: 'ข้อมูลหัวหน้าทีม' },
      { key: 'criminal_status', title: 'ข้อมูลประวัติอาชญากรรม' },
      { key: 'status', title: 'สถานะการอนุมัติ' },
      { key: 'icon', title: '' },
    ];

    const mapStatus = (subContractor) => {
      // รออนุมัติ
      if (subContractor.status === 'registered' && !subContractor.requested_delete) {
        return (
          <>
            <p className="text-primary font-weight">รออนุมัติ</p>
            <p className="text-gray font-weight">{`(${moment(subContractor.updated_date).format('DD/MM/YYYY HH:mm')})`}</p>
          </>
        );
      }

      // อนุมัติ
      if (subContractor.status === 'approved' && !subContractor.has_draft && !subContractor.requested_delete) {
        return (
          <>
            <p className="text-success font-weight">อนุมัติ</p>
            {subContractor.logs_activity && (
              <span className="text-gray font-weight">
                {`(${subContractor.logs_activity.user}, ${moment(subContractor.updated_date).format('DD/MM/YYYY HH:mm')})`}
              </span>
            )}
          </>
        );
      }

      // รออนุมัติการแก้ไข
      if (subContractor.status === 'approved' && subContractor.has_draft && !subContractor.requested_delete) {
        return (
          <>
            <p className="text-primary font-weight">รออนุมัติการแก้ไข</p>
            <p className="text-gray font-weight">{`(${moment(subContractor.updated_date).format('DD/MM/YYYY HH:mm')})`}</p>
          </>
        );
      }

      // ไม่อนุมัติ
      if (subContractor.status === 'rejected'  && !subContractor.requested_delete) {
        return (
          <>
            <p className="text-danger font-weight">ไม่อนุมัติ</p>
            {subContractor.logs_activity && (
              <>
                <p className="font-weight">{subContractor.logs_activity.reason || ''}</p>
                <span className="text-gray font-weight">
                  {`(${subContractor.logs_activity.user}, ${moment(subContractor.updated_date).format('DD/MM/YYYY HH:mm')})`}
                </span>
              </>
            )}
          </>
        );
      }
      
      // ร้องขอการลบบัญชี
      if (subContractor.requested_delete) {
        return (
          <>
            <p className="text-danger font-weight">ร้องขอการลบบัญชี</p>
            <p className="text-gray font-weight">{`(${moment(subContractor.updated_date).format('DD/MM/YYYY HH:mm')})`}</p>
          </>
        );
      }

      return (
        <>
          <p className="text-gray font-weight">ไม่ทราบสถานะ</p>
          <p className="text-gray font-weight">{`(${moment(subContractor.updated_date).format('DD/MM/YYYY HH:mm')})`}</p>
        </>
      );
    };

    const mapCriminalStatus = (status) => {
      const criminalStatus = options.criminal_status.find((item) => item.value === status);

      if (criminalStatus && status === '2') {
        return (
          <>
            <p className="text-primary font-weight">{criminalStatus.label}</p>
            {/* <p className="text-primary font-weight">(ขอผลตรวจผ่านคิวช่าง)</p> */}
          </>
        );
      }

      if (criminalStatus && status === '1') {
        return <p className="text-primary font-weight">{criminalStatus.label}</p>;
      }

      if (criminalStatus && status === '0') {
        return <p className="text-success font-weight">{criminalStatus.label}</p>;
      }

      return <p className="text-gray font-weight">ไม่ทราบสถานะ</p>;
    };

    const mapIcon = (subContractor) => {
      return (
        <div style={{ whiteSpace: 'nowrap' }}>
          {/* edit */}
          <a href={`${config.publicUrl}/superadmin/sub-contractors/${subContractor._id}/edit`}>
          <button className="btn btn-sm btn-warning" title="แก้ไข" data-tip="แก้ไข" onClick={() => this.onClick(subContractor, 'edit')}>
            <i className="fa fa-pencil" aria-hidden="true" />
          </button>
          </a>
          {/* approve */}
          <button
            className="btn btn-sm btn-success"
            title="อนุมัติ"
            data-tip="อนุมัติ"
            onClick={() => this.onClick(subContractor, 'approved')}
            disabled={subContractor.status === 'approved' || !editMode}
          >
            <i className="fa fa-check" aria-hidden="true" />
          </button>
          {/* reject */}
          {subContractor.status !== 'rejected' && (
            <button
              className="btn btn-sm btn-danger"
              title="ไม่อนุมัติ"
              data-tip="ไม่อนุมัติ"
              onClick={() => this.onClick(subContractor, 'rejected')}
              disabled={!editMode}
            >
              <i className="fa fa-times" aria-hidden="true" />
            </button>
          )}
          {/* remove */}
          {subContractor.status === 'rejected' && (
            <button 
              className="btn btn-sm btn-danger"
              title="ลบ"
              data-tip="ลบ"
              onClick={() => this.onClick(subContractor, 'remove')}
              disabled={!editMode}
            >
              <i className="fa fa-trash" aria-hidden="true" />
            </button>
          )}
        </div>
      );
    };

    {
      /* <table className="table table-striped table-bordered table-hover"> */
    }

    return (
      <div className="row">
        <div className="col-md-12">
          <div className="table-responsive">
            <table className="table table-bordered table-hover has-pagination">
              <thead>
                <tr>
                  {columns.map((col) => (
                    <th key={col.key}>{col.title}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {
                  // if subContractors is empty
                  isEmpty(subContractors) && (
                    <tr key={0}>
                      <td colSpan="7" style={{ textAlign: 'center' }}>
                        ไม่มีข้อมูล
                      </td>
                    </tr>
                  )
                }

                {isArray(subContractors) &&
                  subContractors.map((item, index) => {
                    return (
                      <tr key={index}>
                        {columns.map((col) => {
                          if (col.key === 'name') {
                            return (
                              <td key={col.key}>
                                <p>
                                  {item.first_name} {item.last_name}
                                </p>
                                <p>{this.renderPhoneFormat(item.contact_phone_no)}</p>
                              </td>
                            );
                          }

                          if (col.key === 'team_name') {
                            return (
                              <td key={col.key}>
                                <p>{item.main_contractor.team_name}</p>
                              </td>
                            );
                          }

                          if (col.key === 'main_contractor') {
                            return (
                              <td key={col.key}>
                                <p>
                                  <NavLink to={`${config.publicUrl}/superadmin/contractors/${item.main_contractor._id}/taxaddress`} target="_blank">
                                    {item.main_contractor.first_name} {item.main_contractor.last_name}
                                  </NavLink>
                                </p>
                                <p>{this.renderPhoneFormat(item.main_contractor.contact_phone_no)}</p>
                              </td>
                            );
                          }

                          if (col.key === 'criminal_status') {
                            return <td key={col.key}>{mapCriminalStatus(item.criminal_record_check_status)}</td>;
                          }

                          if (col.key === 'status') {
                            return <td key={col.key}>{mapStatus(item)}</td>;
                          }

                          if (col.key === 'icon') {
                            return (
                              <td key={col.key} className="text-center">
                                {mapIcon(item)}
                              </td>
                            );
                          }
                        })}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  onClickClear() {
    this.removeSearchParams();
    localStorage.removeItem('page');
    window.location.reload();
  }

  removeSearchParams() {
    const url = new URL(window.location.href);
    url.searchParams.delete('q');
    url.searchParams.delete('main_contractor_id');
    url.searchParams.delete('status');
    url.searchParams.delete('criminal_status');
    window.history.replaceState({}, '', url.toString());
  }

  render() {
    const url = new URL(window.location.href);
    const { query, options, pagination, isLoading, info } = this.props.subContractor.toJS();
    return (
      <Container isAdmin>
        <Loader show={isLoading} />
        <Notification
          ref={(ref) => {
            this.noti = ref;
          }}
        />
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">ช่าง</h4>
              </div>

              <div className="card-content">
                <div className="col-md-12 d-flex align-items-center">
                  <EnText
                    className="form-control"
                    placeholder="ค้นหาช่างด้วย ชื่อ, นาสกุล, หมายเลขโทรศัพท์"
                    value={url.searchParams.get('q') || query.q}
                    onChange={(e) => this.onChange(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') this.onSearch();
                    }}
                  />
                  <EnButton className="btn-info pull-right" onClick={this.onSearch.bind(this)}>
                    <i className="fa fa-search btn-icon" aria-hidden="true" />ค้นหา
                  </EnButton>
                  <EnButton className="btn-warning pull-right" onClick={this.onClickClear.bind(this)}>
                    <i className="fa fa-refresh btn-icon" aria-hidden="true" />เคลียร์
                  </EnButton>
                </div>

                <div className="form-group col-md-12">
                  <div className="col-md-4">
                    <label className="col-md-4">ทีมช่าง</label>
                    <EnTagSelect
                      className="col-md-8 p-0"
                      multi={false}
                      value={url.searchParams.get('main_contractor_id') || query.main_contractor_id}
                      options={options.contractor_team}
                      onTagChange={(value) => this.onSelect(value, 'main_contractor_id')}
                      placeholder="กรุณาเลือก"
                    />
                  </div>

                  <div className="col-md-4">
                    <label className="col-md-4">สถานะการอนุมัติ</label>
                    <EnTagSelect
                      className="col-md-8 p-0"
                      multi={false}
                      value={url.searchParams.get('status') || query.status}
                      options={options.status}
                      onTagChange={(value) => this.onSelect(value, 'status')}
                      placeholder="กรุณาเลือก"
                    />
                  </div>

                  <div className="col-md-4">
                    <label className="col-md-4">ประวัติอาชญากรรม</label>
                    <EnTagSelect
                      className="col-md-8 p-0"
                      multi={false}
                      value={url.searchParams.get('criminal_status') || query.criminal_status}
                      options={options.criminal_status}
                      onTagChange={(value) => this.onSelect(value, 'criminal_status')}
                      placeholder="กรุณาเลือก"
                    />
                  </div>
                </div>

                <div
                  className="col-md-12"
                  style={{
                    paddingTop: '2rem',
                  }}
                >
                  {this.renderTable(info, options)}
                </div>

                <div className="col-md-12">
                  <Pagination pagination={pagination} handlePageClick={(value) => this.onChangePage(value)} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('contractor', 'subContractor', 'permission', 'auth')(observer(SubContractor));
