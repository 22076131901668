import BaseStore from './BaseStore';
import config from '../config';
import { http } from '../utils/http';

export class Misc extends BaseStore {
  constructor() {
    super();
    this.observable({
      isLoading: false,
      items: [],
      provinceId: undefined,
      districtId: undefined,
      subDistrictId: undefined,
      zipCode: undefined,
      miscData: {
        province: [],
        district: [],
        subDistrict: [],
        branch: [],
      },
    });
  }

  saveId(key, value) {
    if (key.search('miscData') !== -1) {
      const deepKey = key.split('.');
      this.miscData[deepKey[1]] = value;
    } else {
      this[key] = value;
    }
  }

  async getAllProvince() {
    try {
      this.isLoading = true;
      let response = await http.get(`${config.api.sims}/v1/address/provinces`);
      this.miscData.province = response.data.data;
    } catch (error) {
      this.isLoading = false;
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async getProvinceByCode(id) {
    let response = await http.get(`${config.api.sims}/v1/address/provinces/${id}`);
    this.miscData.province = response.data.data;
  }

  async getAllDistrict() {
    let response = await http.get(`${config.api.sims}/v1/address/districts`);
    this.miscData.district = response.data.data;
  }

  async getDistrictByCode(id) {
    let response = await http.get(`${config.api.sims}/v1/address/provinces/${id}/districts`);
    this.miscData.district = response.data.data;
    return response.data.data || [];
  }

  async getDistrictByCodes(codes) {
    let response = await http.get(`${config.api.sims}/v1/address/districts/codes?codes=${codes}`);
    return response.data || [];
  }

  async getProvinceDistricts(id) {
    let response = await http.get(`${config.api.sims}/v1/address/provinces/${id}/districts`);
    return response.data.data || [];
  }

  async getProvinceSubDistricts(id) {
    let response = await http.get(`${config.api.sims}/v1/address/districts/${id}/subdistricts`);
    return response.data.data || [];
  }

  async getAllSubDistrict() {
    let response = await http.get(`${config.api.sims}/v1/address/subdistricts`);
    this.miscData.subDistrict = response.data.data;
  }

  async getSubDistrictByCode(id) {
    let response = await http.get(`${config.api.sims}/v1/address/districts/${id}/subdistricts`);
    this.miscData.subDistrict = response.data.data;
    return response.data.data || [];
  }

  async getDistrictByid(id) {
    let response = await http.get(`${config.api.sims}/v1/address/provinces/${id}/districts`);
    return response.data.data || [];
  }

  async getEmployee() {
    let response = await http.get(`${config.api.sims}/v1/employee`);
    return response.data.data || [];
  }


}

export default new Misc();
