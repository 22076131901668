import React, { Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { required } from '../../../../components/form/FormValidation';
import EnQuill from '../../../../components/form/EnQuill';

export class DescriptionSection extends React.Component {
  onShortDescriptionChanged(value) {
    this.props.product.saveInfo('shortDescription', { th: value });
  }

  onDescriptionChanged(value) {
    this.props.product.saveInfo('description', { th: value });
  }

  render() {
    const { info } = this.props;
    return (
      <Fragment>
        <div className="row">
          <EnQuill containerClassName="col-md-12" label="ข้อมูลสินค้า" value={(info.shortDescription && info.shortDescription.th) || ''} onChange={this.onShortDescriptionChanged.bind(this)} validations={[required]} />
        </div>
        <div className="row">
          <EnQuill containerClassName="col-md-12 editor-container" label="รายละเอียด" value={info.description.th || ''} onChange={this.onDescriptionChanged.bind(this)} validations={[required]} />
        </div>
      </Fragment>
    );
  }
}

export default inject('product')(observer(DescriptionSection));
