import React from 'react';
import { observer, inject } from 'mobx-react';
import EnButton from '../form/EnButton';
import moment from 'moment';
import datetime from '../../utils/datetime';
import Loader from '../common/Loader';
import swal from 'sweetalert2';
import numeral from 'numeral';
import config from '../../config';
import history from '../../utils/history';
import authStore from '../../stores/AuthStore';
import '../../assets/css/bestdeal.css';
import EnDateRangePicker from '../../components/form/EnDateRangePicker';

export class Installments extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            installmentsTable: [],
            optionsQuotation: [],
            valueSelect: "",
            jobId: this.props.match.params.id,
            jobCode: "",
        };
    }

    componentWillUnmount = () => {
        this.props.quotation.subQuotationsInfo = []
    }

    componentDidMount = () => {
        this.props.quotation.handleOptionListQuotations()
    }

    componentDidUpdate = (_, preState) => {
        if (!preState.jobCode) {
            const job = this.props.job;
            if (job.info.jobCode) {
                this.setState({ jobCode: job.info.jobCode })
            }
        }

    }

    commaFormat = (value) => {
        return numeral(value || 0).format('0,0.00')
    }

    checkStatusPaid = (status) => {
        return status === 'paid' || status === 'installation_accepted' || status === 'paid_to_contractor'
    }

    validateSurveyDiscount = (amount, itemInstallments, isSurveyDiscount) => {
        const installment_status = itemInstallments.installment_status
        return (amount < 0 && isSurveyDiscount) || this.checkStatusPaid(installment_status)
    }

    validateLP(subQuotationsInfo) {
      let result = true;
      subQuotationsInfo.map((info) => {
        let total_amount = 0;
        let total_contractor_amount = 0;
        info.items.map((item) => {
          total_amount += item.amount;
          total_contractor_amount += item.contractor_amount;
        })
        if(total_amount <= total_contractor_amount) {
          result = false;
        }
      })

      return result;
    }

    addInstallmentsCreateJobs = async () => {
        const job = this.props.job;
        const { installmentsQuotationInfo, subQuotationsInfo } = this.props.quotation.toJS();
        // validate LP > contractor amount
        if (!this.validateLP(subQuotationsInfo)) {
            swal.fire({
                title: 'ไม่สามารถบันทึกได้',
                html: 'ไม่สามารถดำเนินการได้ เนื่องจากราคาต่อหน่วยของแต่ละงวด<br>มีมูลค่าน้อยกว่าต้นทุนช่างต่อหน่วย',
                icon: 'error',
            });
            return;
        }

        installmentsQuotationInfo.job_code = job.info.jobCode
        installmentsQuotationInfo.sub_quotation = subQuotationsInfo
        installmentsQuotationInfo.created_by = authStore.getUserInfo().email
        const result = await this.props.quotation.addInstallmentsCreateJobs(installmentsQuotationInfo)
        if (result) {
            swal.fire({
                title: 'บันทึกข้อมูลเรียบร้อย',
                icon: 'success',
            });
            history.goBack();
            // const id = this.props.match.params.retuenid ? this.props.match.params.retuenid : this.props.match.params.id
            // history.push(`${config.publicUrl}/superadmin/jobs/${id}`);
        } else {
            swal.fire({
                title: 'บันทึกข้อมูลไม่สำเร็จ',
                icon: 'error',
            });
        }
    }

    // การแบ่งงวด

    deleteRowListQuotation(indexinstallments, indexRow) {
        this.props.quotation.deleteRowListQuotation(indexinstallments, indexRow)
        this.props.quotation.handleOptionListQuotations()
    }

    handleOptionInstallments = (indexinstallments, indexRow, event) => {
        if (!event.target.value) return;
        const { optionsQuotation } = this.props.quotation
        const itemRow = optionsQuotation.find((item) => item.name == event.target.value)
        this.props.quotation.saveSubQuotationAndCalculate(indexinstallments, indexRow, itemRow)
        this.props.quotation.handleOptionListQuotations()
    }

    headerInstallments = (index) => {
        return (
            <thead>
                <tr key={`table${index}`}>
                    <th width="2.5%"></th>
                    <th width="32%" >รายการสินค้า</th>
                    <th width="20%">จำนวนเงิน (รวม VAT)</th>
                    <th width="20%">จำนวนเงินจ่ายช่าง (รวม VAT)</th>
                    <th width="22%" className="text-center">เลขอ้างอิง</th>
                    <th width="5%"></th>
                </tr>
            </thead>
        )
    }

    contentTableInstallments = (itemRow, indexinstallments, indexRow, itemInstallments) => {
        const { disabled } = this.props;
        const isSurveyDiscount = itemRow.is_deposit ? itemRow.is_deposit : false
        return (
            <tbody key={`tbody${indexRow}`}>
                <tr>
                    <td>{indexRow + 1}.</td>
                    <td>
                        <select disabled={disabled || this.validateSurveyDiscount(itemRow.amount, itemInstallments, isSurveyDiscount)} className='form-control' value={itemRow.name} onChange={this.handleOptionInstallments.bind(this, indexinstallments, indexRow)}>
                            <option value={undefined}></option>
                            {
                                this.props.quotation.optionsQuotation.map((item, index) => {
                                    return (itemRow.name === item.name || !this.props.quotation.listQuotationUsed.includes(item.name)) && <option value={item.name} key={index}>{item.name}</option>
                                })
                            }
                        </select>
                    </td>
                    <td><input className='form-control' value={this.commaFormat(itemRow.amount)} disabled /></td>
                    <td><input className='form-control' value={this.commaFormat(itemRow.contractor_amount)} disabled /></td>
                    <td className="text-center"><input className='form-control' value={this.state.jobCode} disabled /></td>
                    <td className="text-right">
                        {
                            !this.validateSurveyDiscount(itemRow.amount, itemInstallments, isSurveyDiscount) &&
                            <EnButton className="btn-icon-installment" disabled={disabled || this.validateSurveyDiscount(itemRow.amount, itemInstallments, isSurveyDiscount)} onClick={() => this.deleteRowListQuotation(indexinstallments, indexRow)}>
                                <i className="fa fa-trash" aria-hidden="true" />
                            </EnButton>
                        }
                    </td>
                </tr>
            </tbody>
        )
    }

    deleteListQuotation = (index) => {
        this.props.quotation.deleteListQuotation(index)
        this.props.quotation.handleOptionListQuotations()
    }

    addRowListQuotation = (index) => {
        this.props.quotation.addRowListQuotation(index)
    }

    saveInsallmentsName = (indexinstallments, event) => {
        this.props.quotation.saveSubQuotation(indexinstallments, 'installment_name', event.target.value)
    }

    addInstallmentsQuotation = () => {
        this.props.quotation.addInstallmentsQuotation()
        this.props.quotation.handleOptionListQuotations()
    }

    // สรุปการแบ่งงวด

    headerSummary = () => {
        return (
            <thead>
                <tr >
                    <th width="4%"></th>
                    <th width="15%" >ชื่องวด</th>
                    <th width="10%">จำนวนเงิน (รวม VAT)</th>
                    <th width="10%">จำนวนเงินจ่ายช่าง (รวม VAT)</th>
                    <th width="22%" >วันที่เริ่มติดตั้ง - วันที่สิ้นสุด</th>
                    <th width="10%" className="text-center">สถานะการชำระเงิน</th>
                </tr>
            </thead>
        )
    }

    statusQuotation = (data) => {
        if (!data) return ''
        switch (data) {
            case 'pending':
                return { label: 'รอลูกค้าชำระเงิน', color: '#337ab7' }
            case 'paid':
                return { label: 'ลูกค้าชำระเงินแล้ว', color: '#3e9342' }
            case 'installation_accepted':
                return { label: 'ลูกค้ายอมรับงานแล้ว', color: '#3e9342' }
            case 'paid_to_contractor':
                return { label: 'จ่ายเงินช่างแล้ว', color: '#acacac' }
            default:
                return { label: 'รอลูกค้าชำระเงิน', color: '#337ab7' }
        }
    }

    checkInstDayBlock(date) {
        const jobInfo = this.props.job.toJS().info;
        const blockDay = this.props.quotation.checkInstDayBlockStore(jobInfo, date)
        return blockDay
    }

    onChangeDateRange(index, installationStartDate, installationEndDate) {
        const dateRange = datetime.GetBetweenDates(installationStartDate, installationEndDate);
        const unavailableInstDate = dateRange.filter(d => { return this.checkInstDayBlock(d) });
        if (unavailableInstDate && unavailableInstDate.length > 0) {
            installationStartDate = undefined;
            installationEndDate = undefined;
        }
        this.props.quotation.saveSubQuotation(index, 'job_start_date', installationStartDate && installationStartDate.toISOString())
        if (installationEndDate) {
            this.props.quotation.saveSubQuotation(index, 'job_end_date', installationEndDate && installationEndDate.toISOString())
        }
    }

    installationDateRangePicker = (data, index, installment_status) => {
        return <EnDateRangePicker
            withPortal
            numberOfMonths={1}
            disabled={this.checkStatusPaid(installment_status)}
            ref={(e) => { this.instDateRange = e; }}
            openDirection="up"
            minimumNights={0}
            startDate={data.job_start_date ? moment(data.job_start_date) : null}
            endDate={data.job_end_date ? moment(data.job_end_date) : null}
            onDateChange={this.onChangeDateRange.bind(this, index)}
            isOutsideRange={(date) => {
                const now = moment().utcOffset(7).startOf('day'); // Current date with UTC offset of +7
                const futureDate = now.clone().add(550, 'days'); // 550 days from now

                return !date.isBetween(now, futureDate, 'day', '[]');
            }}
            isDayBlocked={this.checkInstDayBlock.bind(this)}
            startDatePlaceholderText="วันที่เริ่มต้น"
            endDatePlaceholderText="วันที่สิ้นสุด" />
    }

    calculateOutstandingBalance = () => {
        const resultCalculate = this.calculateQuotation()
        const grandTotal = Number((this.props.quotation.mainQuotation.grand_total || 0).toFixed(2));
        const outstandingBalance = grandTotal - resultCalculate.netDividend;
        resultCalculate.outstandingBalance = outstandingBalance;
        return resultCalculate
    }

    validateCreateInstallment = (subQuotationsInfo) => {
        const result = this.calculateOutstandingBalance()
        const checkInstallmentName = subQuotationsInfo.reduce((pre, item) => {
            return pre && item.installment_name.trim().length > 0
        }, true)
        return result.outstandingBalance !== 0 || !checkInstallmentName
    }

    calculateQuotation = () => {
        const { subQuotationsInfo } = this.props.quotation.toJS();
        let result = {}
        result = subQuotationsInfo.reduce((pre, data) => {
            const { contractor_discount, contractor_amount } = data.items.reduce((pre, item) => {
                pre.contractor_discount += item.contractor_discount
                pre.contractor_amount += item.contractor_amount
                return { contractor_discount: pre.contractor_discount, contractor_amount: pre.contractor_amount }
            }, { contractor_discount: 0, contractor_amount: 0 })
            return {
                netDividend: pre.netDividend + data.grand_total,
                customerDiscount: pre.customerDiscount + data.discount_total,
                netContractor: pre.netContractor + contractor_amount,
                contractorDiscount: pre.contractorDiscount + contractor_discount,
            }
        }, {
            netDividend: 0,
            customerDiscount: 0,
            netContractor: 0,
            contractorDiscount: 0
        })

        result = {
            netDividend: Number(Number(result.netDividend || 0).toFixed(2)),
            customerDiscount: Number(Number(result.customerDiscount || 0).toFixed(2)),
            netContractor: Number(Number(result.netContractor || 0).toFixed(2)),
            contractorDiscount: Number(Number(result.contractorDiscount || 0).toFixed(2)),
        }

        return result
    }

    contentTableSummary = (data, index) => {
        const { disabled } = this.props;
        const { amount, contractor_amount } = data.items.reduce((pre, item) => {
            pre.amount += item.amount
            pre.contractor_amount += item.contractor_amount
            return { amount: pre.amount, contractor_amount: pre.contractor_amount }
        }, { amount: 0, contractor_amount: 0 })
        const installment_status = data.installment_status
        const result = this.statusQuotation(installment_status)
        return (
            <tbody key={`summary${index}`}>
                <tr>
                    <td><b>งวดที่  {index + 1}</b></td>
                    <td><input className='form-control' value={data.installment_name} disabled={disabled || this.checkStatusPaid(installment_status)} onChange={this.saveInsallmentsName.bind(this, index)} maxLength="100" /></td>
                    <td><input className='form-control' value={this.commaFormat(data.grand_total)} disabled /></td>
                    <td><input className='form-control' value={this.commaFormat(contractor_amount.toFixed(2))} disabled /></td>
                    <td>
                        {this.installationDateRangePicker(data, index, installment_status)}
                    </td>
                    <td><input className='form-control' style={{ color: result.color }} value={result.label} disabled /></td>
                </tr>
            </tbody>
        )
    }

    footerSummary = () => {
        const resultCalculate = this.calculateOutstandingBalance()
        return (
            <div className='col-md-offset-8' style={{ fontSize: '1.8rem' }}>
                <div className='col-md-7 content-right'>ยอดแบ่งงวดสุทธิ</div>
                <div className='col-md-5 content-right'>{this.commaFormat(resultCalculate.netDividend)}</div>
                <div className='col-md-7 content-right'>ส่วนลดลูกค้า</div>
                <div className='col-md-5 content-right' style={{ color: 'red' }}>-{this.commaFormat(Math.abs(resultCalculate.customerDiscount))}</div>
                <div className='col-md-7 content-right'>ยอดจ่ายช่างสุทธิ</div>
                <div className='col-md-5 content-right'>{this.commaFormat(Math.abs(resultCalculate.netContractor.toFixed(2)))}</div>
                <div className='col-md-7 content-right'>ส่วนลดช่าง</div>
                <div className='col-md-5 content-right' style={{ color: 'red' }}>-{this.commaFormat(Math.abs(resultCalculate.contractorDiscount))}</div>
                <div className='col-md-7 content-right'>ยอดค้างที่ยังไม่ได้แบ่งงวด</div>
                <div className='col-md-5 content-right'>{this.commaFormat(resultCalculate.outstandingBalance)}</div>
            </div>
        )
    }

    installmentsForm = (subQuotationsInfo) => {
        const { disabled } = this.props;
        return (
            <div>
                {
                    subQuotationsInfo.map((itemInstallments, index) => {
                        return (
                            <div className='card-installments table-responsive' key={`card${index}`}>
                                <h4>งวดที่ {index + 1}</h4>
                                <table className="tableQuotaton" style={{ width: '100%' }} >
                                    {this.headerInstallments(index)}
                                    {
                                        itemInstallments.items.map((item, indexRow) => {
                                            return this.contentTableInstallments(item, index, indexRow, itemInstallments)
                                        })
                                    }
                                </table>
                                {
                                    !this.checkStatusPaid(itemInstallments.installment_status) &&
                                    <EnButton className="btn-success btn-icon-circle" disabled={disabled} onClick={() => this.addRowListQuotation(index)} >
                                        <i className="fa fa-plus" aria-hidden="true" />
                                    </EnButton>
                                }
                                {((subQuotationsInfo.length == index + 1 && index != 0) && !this.checkStatusPaid(itemInstallments.installment_status)) &&
                                    <div className='content-right'>
                                        <EnButton className="btn" disabled={disabled} onClick={() => this.deleteListQuotation(index)} >
                                            <i className="fa fa-trash" aria-hidden="true" />  ลบ
                                        </EnButton>
                                    </div>
                                }
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    summaryInstallments = (subQuotationsInfo) => {
        return (
            <div className='table-responsive'>
                <h4>สรุปการแบ่งงวด</h4>
                <table className="tableQuotaton">
                    {this.headerSummary()}
                    {
                        subQuotationsInfo.map((item, index) => {
                            return this.contentTableSummary(item, index)
                        })
                    }
                </table>
                <div className="content-footer">
                    {this.footerSummary()}
                    <div className='content-right row'>
                        <EnButton disabled={this.validateCreateInstallment(subQuotationsInfo)} className="btn-info" onClick={() => this.addInstallmentsCreateJobs()}>
                            <i className="fa fa-floppy-o btn-icon" aria-hidden="true" /> บันทึก
                        </EnButton>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const { disabled } = this.props;
        const { subQuotationsInfo, isLoading, isSubmitting } = this.props.quotation.toJS();
        const grand_total = subQuotationsInfo.reduce((pre, item) => { return pre + item.grand_total }, 0)
        return (
            <div>
                <Loader show={isLoading || isSubmitting} />
                <div className="row">
                    <div className="col-xs-10">
                        <h4>การแบ่งงวดงาน</h4>
                    </div>
                    <div className="col-xs-2 content-right">
                        <EnButton className='new-register-btn' disabled={disabled} style={{ width: '80%', color: '#fff' }} onClick={() => this.addInstallmentsQuotation()}>เพิ่มงวดงาน</EnButton>
                    </div>
                </div>
                {this.installmentsForm(subQuotationsInfo)}
                {grand_total != 0 && this.summaryInstallments(subQuotationsInfo)}
            </div>
        )
    }
}

export default inject('job', 'quotation')(observer(Installments));