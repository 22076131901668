import React from 'react';
import { observer, inject } from 'mobx-react';
import _ from 'lodash';
import swal from 'sweetalert2';

import { required } from '../../../components/form/FormValidation';
import EnButton from '../../../components/form/EnButton';
import EnDropZone from '../../../components/form/EnDropZone';
import EnQuill from '../../../components/form/EnQuill';
import Loader from '../../../components/common/Loader';
// import ImagePicker from '../../../components/form/ImagePicker';
import FormGroup from '../../../components/form/FormGroup';
import AuthStore from '../../../stores/AuthStore';
import { config } from '../../../config';

import './style/modal.css';

export class DescriptionModal extends React.Component {
  constructor(props) {
    super(props);
    const { data } = props;
    const { content, images, index } = data;
    this.state = {
      isLoading: false,
      addedFiles: [],
      content: content || '',
      images: images || [],
      index,
    };
  }

  onAddedFile() {
    if (this.dropZone) {
      this.setState({
        addedFiles: this.dropZone.getFiles(),
      });
    }
  }

  isValid() {
    const { content, images } = this.state;
    const contentNotBlank = !_.isEmpty(content);
    const imagesNotEmpty = !_.isEmpty(images) || !_.isEmpty(this.state.addedFiles);
    return contentNotBlank || imagesNotEmpty;
  }

  onContentChanged(value) {
    this.setState({ content: value });
  }

  saveData() {
    const { content, images, index } = this.state;
    let { descriptions } = this.props.product.toJS().info;
    if (this.props.isEdit) {
      descriptions[index] = { content, images };
    } else {
      if (!descriptions) {
        descriptions = [];
      }
      descriptions.push({ content, images });
    }
    this.props.product.saveInfo('descriptions', descriptions);
    this.props.onClose();
  }

  async onDropZoneComplete(uploadResults) {
    const { images } = this.state;
    uploadResults.forEach(upload => {
      const result = upload.res ? upload.res.data : null;
      if (result) {
        images.push({
          url: result.path,
          thumbnail: result.thumbnail,
          alt: result.name,
        });
      }
    });
    this.setState({ images });
    this.saveData();
  }

  async doUpload() {
    if (this.dropZone && this.dropZone.getFiles() && this.dropZone.getFiles().length) {
      let files = this.dropZone.getFiles();
      for (let file of files) {
        if (file.status === 'error') {
          throw new Error('ไม่สามารถอัพโหลดไฟล์ได้');
        }
      }
      this.dropZone.upload();
    } else {
      this.saveData();
    }
  }

  async onSubmit() {
    try {
      this.setState({ isLoading: true });
      await this.doUpload();
    } catch (err) {
      swal.fire({
        icon: 'error',
        title: `${err.message}`,
      });
      this.setState({ isLoading: false });
    }
  }

  getDropZone() {
    const token = AuthStore.getToken();
    return (
      <EnDropZone
        ref={(e) => { this.dropZone = e; }}
        url={`${config.api.sims}/v1/jobs/upload`}
        token={token}
        acceptedFiles={'image/*'}
        onAllComplete={(e) => {
          this.onDropZoneComplete(e);
        }}
        maxFileSize={1}
        onAddedFile={this.onAddedFile.bind(this)}
        onError={(err) => {
          swal.fire({
            icon: 'error',
            title: `${err}`,
          });
        }}
      />
    );
  }

  displayImages(images) {
    if (Array.isArray(images) && !images.length) {
      return (
        <div className="col-md-3" key={`image-no`}>
          ยังไม่มีรูปสินค้า
        </div>
      );
    }
    return images.map((image, index) => {
      return (
        <div className="col-md-3" key={`image-${index + 1}`}>
          <img alt="" src={image.url} style={{ border: '1px solid #ccc' }} />
        </div>
      );
    });
  }

  render() {
    const { show, onClose, isEdit } = this.props;
    const { isLoading, content, images } = this.state;
    const dropzone = this.getDropZone();
    return (
      <div className={`modal ${show ? 'show' : 'hide'}`}>
        <Loader show={isLoading} />
        <div className="container-fluid product-modal-container">
          <div className="product-modal-header">
            <div className="row">
              <h3 className="col-md-12" style={{ marginTop: 0, marginBottom: 0 }}>{`${isEdit ? 'แก้ไข' : 'เพิ่ม'}รายละเอียด`}</h3>
            </div>
          </div>
          <div className="product-modal-body">
            <div className="row">
              <EnQuill
                containerClassName="col-md-12"
                label={`รายละเอียด`}
                value={content}
                onChange={this.onContentChanged.bind(this)}
                validations={[required]} />
            </div>
            <div className="row">
              <FormGroup containerClassName="col-md-12" label="รูปภาพ" >
                <div className="col-md-12">
                  {this.displayImages(images)}
                </div>
              </FormGroup>
            </div>
            <div className="row">
              <div className="col-md-12">
                {dropzone}
              </div>
            </div>
          </div>
          <div className="product-modal-footer">
            <div className="row">
              <div className="col-md-12">
                <EnButton className="btn-info pull-right" disabled={!this.isValid()} onClick={this.onSubmit.bind(this)}>
                  <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />
                  บันทึก
                </EnButton>
                <EnButton className="btn-danger pull-right" onClick={onClose}>
                  <i className="fa fa-times btn-icon" aria-hidden="true" />
                  ปิด
                </EnButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default inject('product')(observer(DescriptionModal));
