import moment from 'moment';
import renderHtml from 'react-render-html';

// export const TypeOfWork = [
//   { id: 1, label: 'ฉนวนกันความร้อน ปูใต้หลังคา' },
//   { id: 2, label: 'ประตูหน้าต่างไวนิล (uPVC)' },
//   { id: 3, label: 'รางน้ำฝนไวนิล (uPVC)' },
//   { id: 4, label: 'หลังคาโรงรถและกันสาด' },
//   { id: 5, label: 'พื้นลดแรงกระแทก (Shock Absorption Floor)' },
//   { id: 6, label: 'เปลี่ยนและซ่อมแซมหลังคา (Roof Renovation)' },
//   { id: 7, label: 'ติดตั้งหลังคา' },
//   { id: 8, label: 'ระบบระบายอากาศอัตโนมัติ (Active Air Flow)' },
//   { id: 9, label: 'ระแนงไวนิล (uPVC)' },
//   { id: 10, label: 'ระบบเปิดปิดไฟอัตโนมัติ (Automatic Night Light System)' },
//   { id: 11, label: 'ฝ้าไวนิล (uPVC)' },
//   { id: 12, label: 'ระบบตรวจจับและควบคุมอากาศภายในห้อง (Well Air)' },
//   { id: 13, label: 'ระบบประตูเลื่อนสำหรับใช้งานภายใน (Interior Sliding Door)' },
//   { id: 14, label: 'ลิฟท์บันได (Stair Lift)' },
//   { id: 15, label: 'ติดตั้งสุขภัณฑ์ห้องน้ำ' },
//   { id: 0, label: 'อื่นๆ' },
// ];

export const imageExtension = ['.jpeg',
  '.jpg',
  '.bmp',
  '.gif',
  '.tiff',
  '.eps',
  '.pict',
  '.png',
  '.raw',
  '.webp',
  '.svg',
  '.ico',
  '.psd',
  '.ai'
];

export const AvailableTime = [
  { id: 1, title: 'ช่วงเช้า' },
  { id: 2, title: 'ช่วงบ่าย' },
  { id: 0, title: 'ทั้งวัน' },
];

export const AreaOfWork = [
  { id: 0, label: 'กรุงเทพ' },
  { id: 1, label: 'ปริมณฑล' },
];

export const ProductList = [
  { id: 1, label: 'StayCool 3 นิ้ว' },
  { id: 0, label: 'StayCool 6 นิ้ว' },
];

export const TypeOfJobs = [
  { id: 'A', label: 'A - ทั้งสำรวจและติดตั้ง (Survey & Installation)' },
  { id: 'S', label: 'S - สำรวจหน้างาน (Survey)' },
  { id: 'I', label: 'I - ติดตั้งหน้างาน (Installation)' },
];

export const ContractorStatus = [
  { id: 'all', label: 'All - สถานะทั้งหมด' },
  { id: 'registered', label: 'Registered - ช่างลงทะเบียนใหม่' },
  { id: 'approved', label: 'Approved - อนุมัติแล้ว' },
  { id: 'rejected', label: 'Rejected -  ไม่อนุมัติ' },
  { id: 'blockedPayment', label: 'Blocked payment -  ระงับการจ่ายเงินช่าง' },
  { id: 'paused', label: 'Paused - พักงานชั่วคราว' },
];

export const EventLogs = [
  { value: '', label: 'ทั้งหมด' },
  { value: 'type_of_work', label: 'ประเภทงานที่เพิ่ม' },
  { value: 'is_juristic', label: 'เปลี่ยนแปลงสถานะช่าง บุคคลธรรมดา/นิติบุคคล'},
  { value: 'file', label: 'อัพโหลดเอกสาร' },
  { value: 'vaccine', label: 'เปลี่ยนแปลงข้อมูลวัคซีน' },
]

export const JobStatuses = [
  {
    value: 'created',
    name: 'สร้างใหม่',
  },
  {
    value: 'assigned',
    name: 'ได้รับมอบหมาย',
  },
  {
    value: 'survey_started',
    name: 'เริ่มสำรวจ',
  },
  {
    value: 'survey_finished',
    name: 'สิ้นสุดการสำรวจ',
  },
  {
    value: 'quotation_management',
    name: 'ใบเสนอราคา',
  },
  {
    value: 'installation_requested',
    name: 'นัดติดตั้ง',
  },
  {
    value: 'installation_confirmed',
    name: 'ยืนยันการติดตั้ง',
  },
  {
    value: 'installation_started',
    name: 'เริ่มการติดตั้ง',
  },
  {
    value: 'installation_finished',
    name: 'สิ้นสุดการติดตั้ง',
  },
  {
    value: 'installation_accepted',
    name: 'ลูกค้ายอมรับ',
  },
  {
    value: 'installation_rejected',
    name: 'ลูกค้าปฏิเสธ',
  },
  {
    value: 'paid',
    name: 'ชำระเงิน',
  },
  {
    value: 'deleted',
    name: 'ลบ',
  },
  {
    value: 'paid_to_contractor',
    name: 'จ่ายเงินให้ช่างแล้ว',
  },
  {
    value: 'request_quotation',
    name: 'รอทำใบเสนอราคา',
  },
  {
    value: 'exists_quotation',
    name: 'สร้างใบเสนอราคาแล้ว',
  },
  {
    value: 'contractor_no_view',
    name: 'ช่างยังไม่ทราบ',
  },
  {
    value: 'waiting_for_store_payment',
    name: 'หน้าร้านยังไม่ชำระเงิน',
  },
  {
    value: 'claim',
    name: 'เคลม',
  },
];

export const broadcastRecipientType = [
  { label: 'ส่งทุกคน', value: 'ALL' },
  { label: 'กำหนดตามลูกค้า', value: 'CUSTOMERS' },
  { label: 'กำหนดตามรหัสส่วนลด', value: 'COUPON_GROUP' },
];

export const HolidayList = [{
  isHoliday: true,
  title: 'วันสิ้นปี',
  allDay: true,
  start: moment(new Date(2017, 11, 31)),
  end: moment(new Date(2017, 11, 31)),
},
{
  isHoliday: true,
  title: renderHtml('วันขึ้น<br />ปีใหม่'),
  allDay: true,
  start: moment(new Date(2018, 0, 1)),
  end: moment(new Date(2018, 0, 1)),
},
{
  isHoliday: true,
  title: renderHtml('หยุด<br />ชดเชย<br />วันสิ้นปี'),
  allDay: true,
  start: moment(new Date(2018, 0, 2)),
  end: moment(new Date(2018, 0, 2)),
},
{
  isHoliday: true,
  title: 'วันมาฆบูชา',
  allDay: true,
  start: moment(new Date(2018, 2, 1)),
  end: moment(new Date(2018, 2, 1)),
},
{
  isHoliday: true,
  title: 'วันจักรี',
  allDay: true,
  start: moment(new Date(2018, 3, 6)),
  end: moment(new Date(2018, 3, 6)),
},
{
  isHoliday: true,
  title: 'วันสงกรานต์',
  allDay: true,
  start: moment(new Date(2018, 3, 13)),
  end: moment(new Date(2018, 3, 15)),
},
{
  isHoliday: true,
  title: 'หยุดชดเชยวันสงกรานต์',
  allDay: true,
  start: moment(new Date(2018, 3, 16)),
  end: moment(new Date(2018, 3, 16)),
},
{
  isHoliday: true,
  title: 'วันแรงงาน',
  allDay: true,
  start: moment(new Date(2018, 4, 1)),
  end: moment(new Date(2018, 4, 1)),
},
{
  isHoliday: true,
  title: 'วันวิสาขบูชา',
  allDay: true,
  start: moment(new Date(2018, 4, 29)),
  end: moment(new Date(2018, 4, 29)),
},
{
  isHoliday: true,
  title: 'วันอาสาฬหบูชา',
  allDay: true,
  start: moment(new Date(2018, 6, 27)),
  end: moment(new Date(2018, 6, 27)),
},
{
  isHoliday: true,
  title: 'วันเฉลิมพระชนมพรรษา ร.10',
  allDay: true,
  start: moment(new Date(2018, 6, 28)),
  end: moment(new Date(2018, 6, 28)),
},
{
  isHoliday: true,
  title: 'หยุดชดเชยวันเฉลิมพระชนมพรรษา ร.10',
  allDay: true,
  start: moment(new Date(2018, 6, 30)),
  end: moment(new Date(2018, 6, 30)),
},
{
  isHoliday: true,
  title: 'วันแม่แห่งชาติ',
  allDay: true,
  start: moment(new Date(2018, 7, 12)),
  end: moment(new Date(2018, 7, 12)),
},
{
  isHoliday: true,
  title: 'หยุดชดเชยวันแม่แห่งชาติ',
  allDay: true,
  start: moment(new Date(2018, 7, 13)),
  end: moment(new Date(2018, 7, 13)),
},
{
  isHoliday: true,
  title: 'วันคล้ายวันสวรรคต ร.9',
  allDay: true,
  start: moment(new Date(2018, 9, 13)),
  end: moment(new Date(2018, 9, 13)),
},
{
  isHoliday: true,
  title: 'หยุดชดเชยวันคล้ายวันสวรรคต ร.9',
  allDay: true,
  start: moment(new Date(2018, 9, 15)),
  end: moment(new Date(2018, 9, 15)),
},
{
  isHoliday: true,
  title: 'วันปิยมหาราช',
  allDay: true,
  start: moment(new Date(2018, 9, 23)),
  end: moment(new Date(2018, 9, 23)),
},
{
  isHoliday: true,
  title: 'วันคล้ายวันพระบรมราชสมภพ ร.9',
  allDay: true,
  start: moment(new Date(2018, 10, 5)),
  end: moment(new Date(2018, 10, 5)),
},
{
  isHoliday: true,
  title: 'วันสิ้นปี',
  allDay: true,
  start: moment(new Date(2018, 11, 31)),
  end: moment(new Date(2018, 11, 31)),
},
{
  isHoliday: true,
  title: 'วันขึ้นปีใหม่',
  allDay: true,
  start: moment(new Date(2019, 0, 1)),
  end: moment(new Date(2019, 0, 1)),
}];

const CustomerApproved = 'customer_approved';
const PaidToContractor = 'paid_to_contractor';

export const MarketPlaceHideButton = [
  {
    jobType: 'S',
    button: {
      delete: [CustomerApproved, PaidToContractor],
      editRc: [PaidToContractor],
      complain: [PaidToContractor],
      credit: [PaidToContractor],
      editPo: [PaidToContractor]
    }
  },
  {
    jobType: 'I',
    button: {
      delete: [CustomerApproved, PaidToContractor],
      createQuotation: [CustomerApproved, PaidToContractor],
      addQuotation: [CustomerApproved, PaidToContractor],
      editQuotation: [PaidToContractor],
      editRc: [PaidToContractor],
      complain: [PaidToContractor],
      credit: [PaidToContractor],
      editPo: [PaidToContractor]
    }
  }
]

export const MarketPlaceCreditHideButton = [
  {
    jobType: 'S',
    button: {
      delete: [CustomerApproved, PaidToContractor],
      editRc: [PaidToContractor],
      complain: [PaidToContractor],
      credit: [PaidToContractor],
      editPo: [PaidToContractor]
    }
  },
  {
    jobType: 'I',
    button: {
      delete: [CustomerApproved, PaidToContractor],
      createQuotation: [CustomerApproved, PaidToContractor],
      addQuotation: [CustomerApproved, PaidToContractor],
      editQuotation: [PaidToContractor],
      editRc: [PaidToContractor],
      complain: [PaidToContractor],
      credit: [PaidToContractor],
      editPo: [PaidToContractor]
    }
  }
]

export const RetailHideButton = [
  {
    jobType: 'S',
    button: {
      delete: [CustomerApproved, PaidToContractor],
      createRc: [PaidToContractor],
      editRc: [PaidToContractor],
      complain: [PaidToContractor],
      credit: [PaidToContractor],
      editPo: [PaidToContractor]
    }
  },
  {
    jobType: 'I',
    button: {
      delete: [CustomerApproved, PaidToContractor],
      createQuotation: [CustomerApproved, PaidToContractor],
      addQuotation: [CustomerApproved, PaidToContractor],
      createRc: [PaidToContractor],
      editRc: [PaidToContractor],
      complain: [PaidToContractor],
      credit: [PaidToContractor],
      editPo: [PaidToContractor]
    }
  }
]

export const UPDATE_GRADE_METHOD = {
  TEAM: {
    label: 'เกรดทีม',
    value: 'team',
  },
  WORK: {
    label: 'เกรดตามประเภทงาน',
    value: 'work',
  },
};

export const updateGradeMethodOptions = Object.values(UPDATE_GRADE_METHOD);

export const CHANNEL_GROUP = {
  V1: 'V1',
  V2: 'V2',
};

export const channelGroupOptions = Object.values(CHANNEL_GROUP).map((channelGroup) => ({
  label: `Product Qchang ${channelGroup}`,
  value: channelGroup,
}));

export const getDayOfBirthOptions = (monthOfBirth, yearOfBirth) => {
  const day = [{ label: '--', value: '--' }];
  let dayInMonths;
  if (monthOfBirth === '--') {
    dayInMonths = 31;
  } else {
    dayInMonths = moment(`${yearOfBirth}-${monthOfBirth}`, 'YYYY-MM').daysInMonth();
  }

  for (let idx = 1; idx <= dayInMonths; idx++) {
    let newValue = `0${idx}`;
    newValue = newValue.substring(newValue.length - 2);
    day.push({ label: newValue, value: newValue });
  }

  return day;
}

export const getMonthOfBirthOptions = () => {
  const month = [{ label: '--', value: '--' }];

  for (let idx = 1; idx <= 12; idx++) {
    let newValue = `0${idx}`;
    newValue = newValue.substring(newValue.length - 2);
    month.push({ label: newValue, value: newValue });
  }

  return month;
}

export const getYearOfBirthOptions = () => {
 const year = [...Array(100)].map((_, i) => {
   return { label: moment().year() - i, value: moment().year() - i };
 });
 
 return year;
}

export const getDateOfBirthFlag = (dayOfBirth, monthOfBirth) => {
  let dateOfBirthFlag = 0;
  switch (true) {
    case (dayOfBirth === '--' && monthOfBirth === '--'):
      dateOfBirthFlag = 2;
      break;
    case (dayOfBirth === '--'):
      dateOfBirthFlag = 1;
      break;
    case (monthOfBirth === '--'):
      dateOfBirthFlag = 3;
      break;
    default:
      dateOfBirthFlag = 0;
  }

  return dateOfBirthFlag;
}
