import React from 'react';
import { isNumber } from 'lodash';
import { observer, inject } from 'mobx-react';
import EnTextFormGroup from '../../../../components/form/EnTextFormGroup';
import FormGroup from '../../../../components/form/FormGroup';
import EnButton from '../../../../components/form/EnButton';
import EnCKEditor from '../../../../components/form/EnCKEditor';
import { config } from '../../../../config';
import { Fragment } from 'react';

export class InformationSection extends React.Component {
  onAddNewInfomationClick() {
    this.props.product.addNewInformation();
  }

  onDeleteInfomationClick(index, e) {
    e.preventDefault();
    this.props.product.deleteInformation(index);
  }

  onInfomationChange(index, key, e) {
    if (key !== 'seqNo') {
      this.props.product.saveInformation(index, key, e.target.value);
      return;
    }

    if (+e.target.value > 0) {
      this.props.product.saveInformation(index, key, Math.round(e.target.value));
    } else if (e.target.value.length === 0) {
      this.props.product.saveInformation(index, key, '');
    }
  }

  renderInformations(informations) {
    return (informations || []).map((info, index) => {
      return (
        <div key={`information-${index}`}>
          <div className="row" style={{ paddingLeft: '15px' }}>
            <label>{`ข้อมูลและรายละเอียดชุดที่ ${index + 1}*`}</label>
          </div>
          <div className="row">
            <EnTextFormGroup containerClassName="col-sm-3 col-md-3" type="text" placeholder="seq.No" value={info.seqNo} onChange={this.onInfomationChange.bind(this, index, 'seqNo')} />
          </div>
          <div className="row">
            <EnTextFormGroup containerClassName="col-sm-10 col-md-11" type="text" placeholder="หัวข้อ" value={info.title} onChange={this.onInfomationChange.bind(this, index, 'title')} />
            <div className="col-sm-2 col-md-1 information-button-desktop">
              <EnButton className="btn-danger" style={{ marginTop: '0' }} onClick={this.onDeleteInfomationClick.bind(this, index)}>
                <i className="fa fa-trash" aria-hidden="true" />
              </EnButton>
            </div>
          </div>
          <div className="row">
            <FormGroup containerClassName="col-md-12">
              <EnCKEditor
                data={info.content}
                onChange={(event, editor) => {
                  this.props.product.saveInformation(index, 'content', editor.getData());
                }}
                imageUploadUrl={`${config.api.qchang}/v1/products/upload`}
              />
            </FormGroup>
          </div>
          <div className="row information-button-mobile">
            <div className="col-md-12">
              <EnButton className="btn-danger" style={{ marginTop: '0' }} onClick={this.onDeleteInfomationClick.bind(this, index)}>
                <i className="fa fa-trash" aria-hidden="true" />
              </EnButton>
            </div>
          </div>
        </div>
      );
    });
  }
  render() {
    const { informations } = this.props;
    return (
      <Fragment>
        {this.renderInformations(informations)}
        <div className="row">
          <EnButton className="btn-success" style={{ marginLeft: '15px' }} onClick={this.onAddNewInfomationClick.bind(this)}>
            <i className="fa fa-plus btn-icon" aria-hidden="true" />
            เพิ่มข้อมูลและรายละเอียด
          </EnButton>
        </div>
      </Fragment>
    );
  }
}

export default inject('product')(observer(InformationSection));
