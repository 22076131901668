import React, { Component } from 'react'
import { inject, observer } from 'mobx-react';
import Container from '../../layouts/Container';
import Loader from '../../components/common/Loader';
import EnButton from '../../components/form/EnButton';
import EnTextFormGroup from '../../components/form/EnTextFormGroup';
import Notification from '../../components/common/Notification';
import { checkEmoji, FormValidation, required, validateUrl, } from '../../components/form/FormValidation';
import EnDatePicker from '../../components/form/EnDatePicker';
import FormGroup from '../../components/form/FormGroup';
import EnTextAreaFormGroup from '../../components/form/EnTextAreaFormGroup';
import EnToggle from '../../components/form/EnToggle';
import EnDropDown from '../../components/form/EnDropDown';
import EnTagSelect from '../../components/form/EnTagSelect';
import moment from 'moment'
import _ from 'lodash'
import http from '../../utils/http';
import config from '../../config';
import swal from 'sweetalert2';
import { isAllow } from '../../utils/permission';

class BroadcastCreatePage extends Component {
  frm;
  constructor(props) {
    super(props);
    this.provincesOption = []
    this.gradesOption = []
    this.typeofworksOption = []
    this.typeofworksGroupOption = []
    this.isJuristicOption = []
    this.state = {
      isLoading: false,
      isEdit: false,
      scheduleAnnouncementToggle: false,
      recipientGroupToggle: false,
      formValues: {
        title: "",
        body: "",
        redirect_url: "",
        work_of_areas: [],
        grades: [],
        is_juristics: [],
        code_groups: [],
        type_of_works: [],
        announcement_time: '',
        announcement_date: '',
      },
      selectOptions: {
        provincesOption: [],
        gradesOption: [],
        typeofworksOption: [],
        typeofworksGroupOption: [],
        isJuristicOption: []
      },
    };
  }

  async componentWillMount() {
    const { contractorgrade: contractorGradeStore } = this.props;

    await contractorGradeStore.initGradeMap();
    this.timePickerOption()
    this.resetFormValueAndSelectOption()
    const id = this.props.match.params.id;
    await this.defaultSelectOption()
    if (id) {
      this.setState({ isEdit: true, isLoading: true })
      await this.fetchBroadcast(id)
      this.setState({ isLoading: false })
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const { formValues: prevFormValues, recipientGroupToggle: proveRecipientGroupToggle } = prevState
    const { formValues, recipientGroupToggle } = this.state
    if (formValues.code_groups !== prevFormValues.code_groups) {
      this.autoSetTypeofwork()
    }
    if (recipientGroupToggle !== proveRecipientGroupToggle && proveRecipientGroupToggle) {
      this.resetRecipientGroupForm()
    }
    if (formValues.announcement_date !== prevFormValues.announcement_date && prevFormValues.announcement_date) {
      this.setState({ formValues: { ...formValues, announcement_time: '' } })
    }
  }

  resetRecipientGroupForm() {
    this.setState({
      formValues: {
        ...this.state.formValues,
        work_of_areas: [],
        grades: [],
        is_juristics: [],
        code_groups: [],
        type_of_works: [],
      }
    })
  }

  async fetchBroadcast(id) {
    const { data } = await http.get(`${config.api.sims}/v1/broadcast/${id}`)
    const { work_of_areas, code_groups, grades, is_juristics, type_of_works, ...rest } = data
    if (work_of_areas.length || code_groups.length || grades.length || is_juristics.length || type_of_works.length) {
      this.setState({ recipientGroupToggle: true })
    }
    if (data.announcement_date || data.announcement_time) {
      this.setState({ scheduleAnnouncementToggle: true })
    }
    const listProvinces = work_of_areas.map(item => item.province_id)
    this.handleChangeCodeGroup(code_groups)
    this.handleChangeGrades(grades)
    this.handleChangeIsJuristics(is_juristics)
    this.handleChangeTypeOfwork(type_of_works)
    this.handleChangeWorkofareas(listProvinces)
    this.setState({ formValues: { ...this.state.formValues, announcement_date: moment(data.announcement_date), ...rest } })
  }

  resetFormValueAndSelectOption() {
    this.setState({
      formValues: {
        title: '',
        body: '',
        redirect_url: '',
        work_of_areas: [],
        grades: [],
        is_juristics: [],
        code_groups: [],
        type_of_works: [],
        announcement_date: null,
        announcement_time: ''
      },
      selectOptions: {
        provincesOption: [],
        gradesOption: [],
        typeofworksOption: [],
        typeofworksGroupOption: [],
        isJuristicOption: [],
      }
    })
  }

  async defaultSelectOption() {
    const { contractorgrade: contractorGradeStore } = this.props;
    const { gradeMap } = contractorGradeStore.toJS();

    this.setState({ isLoading: true })
    await this.props.misc.getAllProvince();
    await this.props.typeofwork.getTypeOfWorks()
    const miscStore = this.props.misc.toJS();
    const provincesOption = miscStore.miscData.province.map(province => ({ label: province.name, value: province.code }));
    const gradesOption = Object.entries(gradeMap).map(([originalGrade, displayedGrade]) => ({ label: displayedGrade, value: originalGrade }));
    const isJuristicOption = [
      { label: 'บุคคลธรรมดา', value: 'none-juristic' },
      { label: 'นิติบุคคล', value: 'juristic' },
    ]

    const filterTypeofworks = this.props.typeofwork.items.filter(typeofwork => typeofwork.code && typeofwork.name)
    const sortTypeofworks = filterTypeofworks.sort((a, b) => a.code < b.value ? -1 : (a.value > b.value ? 1 : 0))
    const typeofworksOption = sortTypeofworks.map(typeofwork => ({ label: `${typeofwork.name}`, value: typeofwork.value, code_group: typeofwork.code_group }))

    const filterTypeofworksGroup = this.props.typeofwork.items.filter(typeofwork => typeofwork.code_group)
    const uniqTypeofworksGrpup = [...new Map(filterTypeofworksGroup.map(item => [item['code_group'], item])).values()]
    const sortTypeofworksGroup = uniqTypeofworksGrpup.sort((a, b) => a.code_group < b.code_group ? -1 : (a.code_group > b.code_group ? 1 : 0))
    const typeofworksGroupOption = sortTypeofworksGroup.map(typeofwork => ({ label: `${typeofwork.group_name}`, value: typeofwork.code_group }))

    this.provincesOption = provincesOption
    this.gradesOption = gradesOption
    this.typeofworksOption = typeofworksOption
    this.typeofworksGroupOption = typeofworksGroupOption
    this.isJuristicOption = isJuristicOption
    this.setState({ selectOptions: { provincesOption, gradesOption, typeofworksOption, typeofworksGroupOption, isJuristicOption } })
    this.setState({ isLoading: false })
  }

  handleChangFieldForm(key, e) {
    if (key === 'announcement_date') {
      return this.setState({ formValues: { ...this.state.formValues, ['announcement_date']: new Date(e.toDate()).toISOString() } })
    }

    if (e.target) {
      this.setState({ formValues: { ...this.state.formValues, [key]: e.target.value } })
    }
  }

  handleChangeWorkofareas = (values) => {
    const listValues = typeof values === 'string' ? values.split(",") : values
    const cloneOption = [...this.provincesOption]
    const optionSlected = cloneOption.filter(item => listValues.includes(item.value))
    const optionNoneSeleted = cloneOption.filter(item => !listValues.includes(item.value))
    return this.setState({
      selectOptions: { ...this.state.selectOptions, provincesOption: optionNoneSeleted },
      formValues: { ...this.state.formValues, work_of_areas: optionSlected }
    })
  }

  handleChangeGrades = (values) => {
    const listValues = typeof values === 'string' ? values.split(",") : values
    const cloneOption = [...this.gradesOption]
    const optionSlected = cloneOption.filter(item => listValues.includes(item.value))
    const optionNoneSeleted = cloneOption.filter(item => !listValues.includes(item.value))
    return this.setState({
      selectOptions: { ...this.state.selectOptions, gradesOption: optionNoneSeleted },
      formValues: { ...this.state.formValues, grades: optionSlected }
    })
  }

  handleChangeIsJuristics = (values) => {
    const listValues = typeof values === 'string' ? values.split(",") : values
    const cloneOption = [...this.isJuristicOption]
    const optionSlected = cloneOption.filter(isJuristic => listValues.includes(isJuristic.value))
    const optionNoneSeleted = cloneOption.filter(isJuristic => !listValues.includes(isJuristic.value))
    return this.setState({
      selectOptions: { ...this.state.selectOptions, isJuristicOption: optionNoneSeleted },
      formValues: { ...this.state.formValues, is_juristics: optionSlected }
    })
  }

  handleChangeCodeGroup = (values) => {
    const newSelectOption = { ...this.state.selectOptions }
    const newFormValue = { ...this.state.formValues }
    const listValues = typeof values === 'string' ? values.split(",") : values
    const cloneGroupTypeOfwork = [...this.typeofworksGroupOption]
    const groupTypeOfworkOptionSeleted = cloneGroupTypeOfwork.filter(item => listValues.includes(item.value))
    const optionNoneSeleted = cloneGroupTypeOfwork.filter(item => !listValues.includes(item.value))
    this.setState({
      selectOptions: { ...newSelectOption, typeofworksGroupOption: optionNoneSeleted },
      formValues: { ...newFormValue, code_groups: groupTypeOfworkOptionSeleted }
    })
  }

  autoSetTypeofwork() {
    const { selectOptions, formValues, ...rest } = { ...this.state }
    const { typeofworksOption } = selectOptions
    const { code_groups, type_of_works } = formValues
    const code_groupValues = code_groups.map(item => item.value)
    const selectTypeofwork = typeofworksOption.filter(item => code_groupValues.includes(item.code_group))
    const selectTypeofworkValue = selectTypeofwork.map(item => item.value)
    const selectOldTypeofworkValue = type_of_works.map(item => item.value)
    this.handleChangeTypeOfwork([...selectTypeofworkValue, ...selectOldTypeofworkValue])
  }

  handleChangeTypeOfwork = (values) => {
    const newSelectOption = { ...this.state.selectOptions }
    const newFormValue = { ...this.state.formValues }
    const listValues = typeof values === 'string' ? values.split(",").map(item => parseInt(item)) : values
    const cloneOption = [...this.typeofworksOption]
    const optionSelected = cloneOption.filter(item => listValues.includes(item.value))
    const optionNoneSelected = cloneOption.filter(item => !listValues.includes(item.value))
    this.setState({
      selectOptions: { ...newSelectOption, typeofworksOption: optionNoneSelected },
      formValues: { ...newFormValue, type_of_works: optionSelected }
    })
  }

  validateForm(info) {
    const isRequiredTitle = required(info.title)
    const isRequiredBody = required(info.body)
    const isEmojiTitle = checkEmoji(info.title)
    const isEmojiBody = checkEmoji(info.body)
    const isValidateUrl = validateUrl(info.redirect_url)
    return !isRequiredBody && !isRequiredTitle && !isEmojiBody && !isEmojiTitle && !isValidateUrl
  }
  async handleSubmit() {
    try {
      const { formValues, isEdit } = this.state
      this.frm.isValid()
      const isFormError = this.validateForm(formValues)
      if (!isFormError) {
        swal.fire({
          icon: 'error',
          title: 'กรุณากรอกข้อมูลให้ถูกต้อง',
          confirmButtonText: 'ตกลง',
        });
        return;
      }
      const _data = {
        ...formValues,
        work_of_areas: formValues.work_of_areas.map(item => ({ province_id: item.value })),
        grades: formValues.grades.map(item => item.value),
        code_groups: formValues.code_groups.map(item => item.value),
        is_juristics: formValues.is_juristics.map(item => item.value),
        type_of_works: formValues.type_of_works.map(item => item.value),
      }
      if (!_data.announcement_date) {
        _data.announcement_date = new Date()
      }
      if (!_data.announcement_time) {
        const hour = new Date().getHours()
        const minute = new Date().getMinutes()
        _data.announcement_time = minute < 30 ? `${hour + 1}:00` : `${hour + 2}:00`
      }

      if (isEdit) {
        const id = this.props.match.params.id;
        await http.patch(`${config.api.sims}/v1/broadcast/${id}`, _data);
      } else {
        await http.post(`${config.api.sims}/v1/broadcast`, _data);
      }

      swal.fire({ title: 'บันทึกข้อมูลเรียบร้อย', icon: 'success', }).then(async (result) => {
        if (result.value) this.props.history.push(`${config.publicUrl}/superadmin/broadcasts`)
      })
    }
    catch (error) {
      this.noti.error(error.message, 'ตรวจสอบคำร้องขอ');
    }
  }

  isValid() {
    const { formValues, recipientGroupToggle, scheduleAnnouncementToggle } = this.state
    const { work_of_areas, grades, is_juristics, code_groups, type_of_works, announcement_time, announcement_date, } = formValues
    const validTitle = _.isEmpty(formValues.title)
    const validBody = _.isEmpty(formValues.body)

    if (validTitle || validBody || formValues.is_published) {
      return true
    }

    if (recipientGroupToggle) {
      return !work_of_areas.length && !grades.length && !is_juristics.length && !code_groups.length && !type_of_works.length
    }

    if (scheduleAnnouncementToggle) {
      return !announcement_time || !announcement_date
    }
  }

  onCancel() {
    this.resetFormValueAndSelectOption()
    this.props.history.push(`${config.publicUrl}/superadmin/broadcasts`)
  }

  timePickerOption() {
    const { formValues } = this.state
    const currentDate = new Date().getDate()
    const selectedDate = new Date(formValues.announcement_date).getDate()
    const minCurrent = new Date().getMinutes()
    const hourCurrent = minCurrent > 30 ? new Date().getHours() + 2 : new Date().getHours() + 1
    const timeOption = _.range(0, 24).map(number => currentDate === selectedDate && hourCurrent > number
      ? <option disabled value={moment({ hour: number }).format('HH:mm')}>{moment({ hour: number }).format('HH:mm')}</option>
      : <option value={moment({ hour: number }).format('HH:mm')}>{moment({ hour: number }).format('HH:mm')}</option>
    )
    return timeOption
  }

  render() {
    const { scheduleAnnouncementToggle, recipientGroupToggle, formValues, selectOptions, isLoading, isEdit, is_published } = this.state
    const { provincesOption, gradesOption, typeofworksGroupOption, typeofworksOption, isJuristicOption } = selectOptions
    const title = isEdit ? 'แก้ไข ประกาศ' : 'สร้างประกาศใหม่';

    return (
      <Container isAdmin>
        <div className="row">
          <Loader show={isLoading} />
          <Notification ref={(ref) => { this.noti = ref; }} />
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">{title}</h4>
                <p className="category">กรุณากรอกข้อมูลให้ครบถ้วน</p>
              </div>
              <div className="card-content">
                <FormValidation ref={(el) => { this.frm = el }}>
                  <div className="row">
                    <div style={{ marginTop: '2rem' }} />
                    <FormGroup validations label="หัวข้อ" containerClassName="col-md-12">
                      <EnTextFormGroup
                        disabled={formValues.is_published}
                        id="title"
                        type="text"
                        value={formValues['title']}
                        onChange={(e) => this.handleChangFieldForm('title', e)}
                        validations={[required, checkEmoji]}
                      />
                    </FormGroup>
                    <FormGroup validations label="เนื้อหา" containerClassName="col-md-12">
                      <EnTextAreaFormGroup
                        disabled={formValues.is_published}
                        id="body"
                        rows="5"
                        value={formValues['body']}
                        onChange={(e) => this.handleChangFieldForm('body', e)}
                        validations={[required, checkEmoji]}
                      />
                    </FormGroup>
                    <FormGroup label="ลิ้งค์แนบ" containerClassName="col-md-12">
                      <EnTextFormGroup
                        placeholder="http://"
                        disabled={formValues.is_published}
                        id="redirect_url"
                        type="text"
                        value={formValues['redirect_url']}
                        onChange={(e) => this.handleChangFieldForm('redirect_url', e)}
                        validations={[validateUrl]}
                        notRequired
                      />
                    </FormGroup>
                    <div className="col-md-12" style={{ padding: "0, 2rem" }}>
                      <p>กำหนดกลุ่มผู้ได้รับประกาศ **หากไม่กำหนด ช่างทุกคนจะได้รับประกาศ </p>
                      <EnToggle
                        disabled={isAllow("DEFINE_BROADCAST_TARGET_AUDIENCE_GROUP") ? formValues.is_published : true}
                        checked={this.state.recipientGroupToggle}
                        onChange={() => this.setState({ recipientGroupToggle: !this.state.recipientGroupToggle })}
                      />
                    </div>
                    <FormGroup label="เลือกพื้นที่รับงาน" containerClassName="col-md-12">
                      <EnTagSelect
                        id="work_of_areas"
                        placeholder="เลือกจังหวัด"
                        disabled={!recipientGroupToggle}
                        closeOnSelect={false}
                        options={provincesOption}
                        onTagChange={(value) => this.handleChangeWorkofareas(value)}
                        value={formValues['work_of_areas']}
                        searchable
                      />
                    </FormGroup>
                    <FormGroup label="เลือกเกรดทีมช่าง" containerClassName="col-md-12">
                      <EnTagSelect
                        id="grades"
                        disabled={!recipientGroupToggle}
                        closeOnSelect={false}
                        options={gradesOption}
                        onTagChange={(value) => this.handleChangeGrades(value)}
                        value={formValues['grades']}
                        searchable
                      />

                    </FormGroup>
                    <FormGroup label="เลือกประเภทช่าง" containerClassName="col-md-12">
                      <EnTagSelect
                        id="is_juristics"
                        disabled={!recipientGroupToggle}
                        closeOnSelect={false}
                        options={isJuristicOption}
                        onTagChange={(value) => this.handleChangeIsJuristics(value)}
                        value={formValues['is_juristics']}
                        searchable
                      />
                    </FormGroup>
                    <FormGroup label="เลือกกลุ่มประเภทงาน" containerClassName="col-md-12">
                      <EnTagSelect
                        disabled={!recipientGroupToggle}
                        closeOnSelect={false}
                        options={typeofworksGroupOption}
                        onTagChange={(value) => this.handleChangeCodeGroup(value)}
                        value={formValues['code_groups']}
                        searchable
                      />
                    </FormGroup>
                    <FormGroup label="เลือกประเภทงาน" containerClassName="col-md-12">
                      <EnTagSelect
                        disabled={!recipientGroupToggle}
                        closeOnSelect={false}
                        options={typeofworksOption}
                        onTagChange={(value) => this.handleChangeTypeOfwork(value)}
                        value={formValues['type_of_works']}
                        searchable
                      />
                    </FormGroup>
                    <div className="col-md-12">
                      <p>กำหนดเวลาการประกาศ **หากไม่กำหนดเวลาการประกาศ จะประกาศในชั่วโมงถัดไป</p>
                      <EnToggle
                        disabled={isAllow("SET_BROADCAST_DATE_AND_TIME") ? formValues.is_published : true}
                        checked={this.state.scheduleAnnouncementToggle}
                        onChange={() => this.setState({ scheduleAnnouncementToggle: !this.state.scheduleAnnouncementToggle })}
                      />
                    </div>
                    <div style={{ padding: '0 1rem' }}>
                      <div className="row">
                        <FormGroup containerClassName="col-md-6" label="วันประกาศ">
                          <EnDatePicker
                            id="announcement_date"
                            initialDate={formValues.announcement_date ? moment(formValues.announcement_date) : undefined}
                            disabled={!scheduleAnnouncementToggle || formValues.is_published}
                            onDateChange={(value) => this.handleChangFieldForm('announcement_date', value)}
                          // isOutsideRange={() => true}

                          />
                        </FormGroup>
                        <FormGroup containerClassName="col-md-6" label="เวลาประกาศ">
                          <EnDropDown
                            id="announcement_time"
                            value={formValues['announcement_time']}
                            disabled={!formValues.announcement_date || !scheduleAnnouncementToggle || formValues.is_published}
                            onChange={value => { this.handleChangFieldForm('announcement_time', value) }}
                          >
                            {this.timePickerOption()}
                          </EnDropDown>
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {formValues.is_published
                      ? <EnButton
                        className="btn-danger pull-right"
                        onClick={() => this.props.history.push(`${config.publicUrl}/superadmin/broadcasts`)}
                      >
                        <i class="fa fa-arrow-left" aria-hidden="true"></i>
                      </EnButton>
                      : <span>
                        <EnButton
                          className="btn-info pull-right"
                          disabled={this.isValid()}
                          onClick={() => this.handleSubmit()}
                        >
                          <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
                        </EnButton>
                        <EnButton
                          disabled={formValues.is_published}
                          className="btn-danger pull-right"
                          onClick={() => this.onCancel()}
                        >
                          <i className="fa fa-times btn-icon" aria-hidden="true" />ยกเลิก
                        </EnButton>
                      </span>
                    }
                  </div>
                </FormValidation>
              </div>
            </div>
          </div>
        </div>
      </Container >
    );
  }
}

export default inject('misc', 'typeofwork', 'contractorgrade')(observer(BroadcastCreatePage));
