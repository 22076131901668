import React from 'react';
import { observer, inject } from 'mobx-react';
import { DropdownButton, MenuItem } from "react-bootstrap";
import history from '../utils/history';
import config from '../config';

export class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      displayName: '',
    };
  }

  componentDidMount() {
    this.setUsernameOnTopbar();
  }

  setUsernameOnTopbar() {
    let auth = this.props.auth.getStore();
    let isSuperAdmin = this.props.auth.isSuperAdmin();
    let isAdminBU = this.props.auth.isAdminBU();
    let storeName = '';
    if (isSuperAdmin) {
      storeName = 'Super Admin';
    } else if (isAdminBU) {
      storeName = 'Admin BU';
    } else if (auth && auth.name) {
      storeName = auth.name || '';
    }

    let currentRole = this.props.auth.getCurrentRole();
    this.setState({ displayName : storeName});

    if (currentRole) {
      storeName = (currentRole && currentRole.name) || storeName;
      const userInfo = this.props.auth.getUserInfo();
      const userName = userInfo.displayName || userInfo.email || '';
      this.setState({ displayName : `${userName} (${storeName}) `})
    }
  }

  toggleMenu() {
    let lastState = this.state.showMenu;
    this.setState({
      showMenu: !lastState,
    }, () => {
      let doc = document.getElementsByClassName('perfect-scrollbar-on');
      if (doc && doc.length) {
        let hDoc = doc[0];
        hDoc.classList.toggle('nav-open', !lastState);
      }
    });
  }

  async handleSwitchRole(uuid) {
    const { auth: authStore } = this.props;
    let currentRole = this.props.auth.getCurrentRole();
    if (currentRole.uuid === uuid) return;
    await this.props.auth.setPermissions(uuid);
    currentRole = this.props.auth.getCurrentRole();

    if(authStore.isSuperAdmin()) {
      history.push(`${config.publicUrl}/superadmin/jobs`);
    } else {
      history.push(`${config.publicUrl}/admin/calendar`);
    }
    this.setUsernameOnTopbar();
  }

  render() {
    let currentRole = this.props.auth.getCurrentRole();
    let roles = this.props.auth.getUserRole();
    return (
      <nav className="navbar navbar-transparent navbar-absolute">
        <div className="container-fluid">
          <div className="navbar-header">
            <a className="navbar-brand user-name-text" onClick={this.toggleMenu.bind(this)}>
              <i className="fa fa-user-circle" aria-hidden="true" />
              {this.state.displayName}
            </a>
            {currentRole && (
              <DropdownButton
                bsStyle="default"
                title="Switch role to"
                id="dropdown-switch-tole"
                className="switch-role"
              >
                {roles.map(
                  (role) =>
                    role && (
                      <MenuItem
                        key={role.uuid}
                        eventKey={role.uuid}
                        onSelect={this.handleSwitchRole.bind(this)}
                        active={currentRole.uuid === role.uuid}
                        className="switch-role-text"
                      >
                        {role.name}
                      </MenuItem>
                    )
                )}
              </DropdownButton>
            )}
            <button type="button" className="navbar-toggle" data-toggle="collapse" onClick={this.toggleMenu.bind(this)}>
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar" />
              <span className="icon-bar" />
              <span className="icon-bar" />
            </button>
          </div>
        </div>
      </nav>
    );
  }
}

export default inject('auth')(observer(NavBar));
