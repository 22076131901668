import BaseStore from './BaseStore';
import config from '../config';
import { http } from '../utils/http';
import * as _ from 'lodash';
import moment from 'moment';

export class DownloadStore extends BaseStore {
  constructor() {
    super();
    this.observable({
      isLoading: false,
      items: [],
      query: this.initQuery(),
      listFileUploade: []
    });
  }

  initQuery() {
    // const monthPast = moment().subtract(1, 'months').startOf('day').startOf('month');
    // const now = moment().startOf('day');
    return {
      channel_list: undefined,
      sale_model_list: undefined,
      type_of_job: undefined,
      type_of_work_list: undefined,
      contractor_id_list: undefined,
      status_list: undefined,
      start_date: undefined,
      end_date: undefined,
      create_start_date: undefined,
      create_end_date: undefined,
      isTranferMC: undefined,
      payment_channel: undefined,
      status: undefined
    };
  }

  setQuery(key, value) {
    this.query[key] = value;
  }

  setDefaultQuery() {
    this.query = this.initQuery();
  }

  setLoading(value) {
    this.isLoading = value;
  }

  prepareOptions(queries) {
    const query = {};
    if (queries.channel_list) {
      query.channel_list = _.uniq(queries.channel_list.split(','));
    }

    if (queries.contractor_id_list) {
      query.contractor_id_list = _.uniq(queries.contractor_id_list.split(','));
    }

    if (queries.sale_model_list) {
      query.sale_model_list = _.uniq(queries.sale_model_list.split(','));
    }

    if (queries.status_list) {
      query.status_list = _.uniq(queries.status_list.split(','));
    }

    if (queries.type_of_job) {
      query.type_of_job = queries.type_of_job;
    }

    if (queries.type_of_work_list) {
      query.type_of_work_list = _.uniq(queries.type_of_work_list.split(',')).map(item => parseInt(item));
    }

    if (queries.start_date) {
      query.start_date = moment(queries.start_date).format("YYYY-MM-DD");
    }

    if (queries.end_date) {
      query.end_date = moment(queries.end_date).format("YYYY-MM-DD");
    }

    if (queries.create_start_date) {
      query.create_start_date = moment(queries.create_start_date).format("YYYY-MM-DD");
    }

    if (queries.create_end_date) {
      query.create_end_date = moment(queries.create_end_date).format("YYYY-MM-DD");
    }
    if (queries.isTranferMC) {
      query.isTranferMC = queries.isTranferMC;
    }
    if (queries.payment_channel) {
      query.payment_channel = _.uniq(queries.payment_channel.split(','));
    }
    if (queries.status) {
      query.status = queries.status;
    }
    return query;
  }

  async getDownloadFiles(downloadType, channel, storeId, buStoreId) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      let url = `${config.api.sims}/v1/downloads/file?type=${downloadType || ''}&channel=${(channel || '')}&storeId=${storeId || ''}&buStoreId=${buStoreId || ''}`;
      const response = await http.get(url);
      if (response.status === 200 && response.data && response.data.data) {
        this.items = response.data.data;
      }
    } catch (err) {
      throw err;
    } finally {
      this.isLoading = false;
    }
  }

  async updateStatusDownload(options) {
    try {
      let url = `${config.api.sims}/v1/downloads/file/jobs/updateStatus`;
      const response = await http.post(url, options);
      if (response.status !== 200) {
        throw new Error('การยกเลิกล้มเหลว');
      }

      if (response.status === 200) {
        return response.status;
      }
    } catch (error) {
      throw error;
    }
  }

  async createDownloadFiles(options) {
    if (this.isLoading) return;
    this.isLoading = true;
    console.log('options >>>>>>>>>>>>>>>>', options);
    try {
      let url = `${config.api.sims}/v1/downloads/file/jobs`;
      const response = await http.post(url, options);
      if (response.status !== 200) {
        throw new Error('การดาวน์โหลดล้มเหลว');
      }

      if (response.status === 200) {
        return response.status;
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async getUploadeFiles(downloadType, channel, storeId) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      let url = `${config.api.sims}/v1/downloads/file?type=${downloadType || ''}&channel=${channel || ''}&storeId=${storeId || ''}`;
      const response = await http.get(url);
      if (response.status === 200 && response.data && response.data.data) {
        this.listFileUploade = response.data.data;
      }
    } catch (err) {
      throw err;
    } finally {
      this.isLoading = false;
    }
  }

  async getSignUrl(fileName, filePath) {
    try {
      const url = `${config.api.sims}/v1/downloads/signUrl`;
      const response = await http.get(url, {
        params: {
          fileName,
          filePath,
        }
      });
      if (response.status === 200 && response.data && response.data.data) {
        return response.data.data;
      }
      return {};
    } catch (err) {
      throw err;
    } finally {
      this.isLoading = false;
    }
  }

  async getAllSignUrl(filePath) {
    try {
      const url = `${config.api.sims}/v1/downloads/signAllUrl`;
      const response = await http.get(url, {
        params: {
          filePath
        }
      });
      if (response.status === 200 && response.data && Array.isArray(response.data.data)) {
        return response.data.data;
      }
      return [];
    } catch (err) {
      throw err;
    } finally {
      this.isLoading = false;
    }
  }
}

export default new DownloadStore();
