import React from 'react';
import * as _ from 'lodash';
import { observer, inject } from 'mobx-react';
import FormGroup from '../../components/form/FormGroup';
import { isAllow } from '../../utils/permission';
import EnButton from '../../components/form/EnButton';
import EnTagSelect from '../../components/form/EnTagSelect';

export class AdminTypeOfWorkContractor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      typeOfWorkItems: [],
      typeOfWorksContractor: [],
    };
  }

  async componentDidMount() {
    await this.loadTypeOfWork();
  }

  render() {
    return (
      <div className="card-content">
        <div className="row">
          <FormGroup containerClassName="col-md-12">
            {this.showContractorTypeOfWork()}
          </FormGroup>
        </div>
        <div className="row">
          <FormGroup containerClassName="col-md-12">
            {this.showItem()}
            <br />
            <br />
            <EnButton className="btn-info pull-right" disabled={isAllow("APPROVE_OR_REJECT_A_TYPE_OF_WORK") ? !this.validationTypeOfWork() : true} onClick={this.clickSubmitTypeOfWork.bind(this)}>
              <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
            </EnButton>
          </FormGroup>
        </div>
      </div>
    );
  }

  async loadTypeOfWork() {
    try {
      await this.props.typeofwork.getTypeOfWorks();
      const contractorInfo = this.props.contractor.toJS().info;
      const cTypeOfWork = contractorInfo.typeOfWorkApproved && contractorInfo.typeOfWorkApproved.length > 0 ? contractorInfo.typeOfWorkApproved : contractorInfo.typeOfWork;
      const allTypeOfWorks = this.props.typeofwork.toJS().items;
      if (allTypeOfWorks) {
        if (Array.isArray(allTypeOfWorks) && allTypeOfWorks.length) {
          let data = allTypeOfWorks;
          data['value'] = cTypeOfWork;
          this.setState({ typeOfWorkItems: [data] });
        } else {
          this.setState({ typeOfWorkItems: [allTypeOfWorks] });
        }
      }
      if (contractorInfo.typeOfWork) {
        const typeOfWorks = [];
        contractorInfo.typeOfWork.forEach(value => {
          const typeOfWork = allTypeOfWorks.find((t) => t.value === value);
          typeOfWorks.push(typeOfWork);
        });
        if (Array.isArray(typeOfWorks) && typeOfWorks.length) {
          let data = typeOfWorks;
          data['value'] = cTypeOfWork;
          this.setState({ typeOfWorksContractor: [data] });
        } else {
          this.setState({ typeOfWorksContractor: [typeOfWorks] });
        }
      }
    } catch (error) {
      throw error;
    }
  }

  validationTypeOfWork() {
    let check = this.state.typeOfWorkItems.map(d => {
      return !(_.isEmpty(d['value']) || d['value'][0] === '');
    });
    let isValid = _.indexOf(check, false);
    return isValid < 0;
  }

  showItem() {
    let typeOptions;
    let items = (this.state.typeOfWorksContractor || []).map((item, index) => {
      typeOptions = (item || []).map(d => {
        return ({
          label: d && d['name'] ? d['name'] : '',
          value: d && d['value'] ? d['value'] : '',
        });
      });
      typeOptions = _.orderBy(typeOptions, ['label']);
      return (
        <div key={`item-${index}`} className="card item-card">
          <div className="card-content">
            <FormGroup label="เลือกประเภทงานที่อนุมัติ">
              <EnTagSelect
                closeOnSelect={false}
                options={typeOptions}
                onTagChange={this.onTypeOfWorkChange.bind(this, item)}
                value={item['value']}
                searchable
              />
            </FormGroup>
          </div>
        </div>
      );
    });

    return items;
  }

  showContractorTypeOfWork() {
    let typeOfWork = '';
    const contractorInfo = this.props.contractor.toJS().info;
    const cTypeOfWork = contractorInfo.typeOfWork;
    const stores = contractorInfo.stores || []

    if (this.state.typeOfWorkItems && this.state.typeOfWorkItems.length > 0) {
      typeOfWork = this.state.typeOfWorkItems[0].map((d, idx) => {
        if (cTypeOfWork.indexOf(d.value) >= 0) {
          return <p key={idx}>- {d.name}</p>;
        }
        return null;
      });
    }
    return (
      <div className="card item-card">
        <div className="card-content" style={{ display: 'flex' }}>
          <div label="ประเภทงานที่ช่างเลือก" style={{ width: '100%' }}>
            <b style={{ display: 'inline-block', marginBottom: '1rem' }}>ประเภทงานที่ช่างเลือก</b>
            {typeOfWork}
          </div>
          <div label="ร้านค้าของช่าง" style={{ width: '100%' }}>
            <b style={{ display: 'inline-block', marginBottom: '1rem' }}>ร้านค้าของช่าง</b>
            {stores.length ? stores.map(store => (
              <>
                <p>{`- ${store.name}`}</p>
              </>
            )) : <p style={{ color: 'gray' }}>ช่างยังไม่ถูกเลือกเข้าร้าน</p>}
          </div>
        </div>
      </div>
    );
  }

  async clickSubmitTypeOfWork() {
    try {
      let selectedTypeOfWork = this.state.typeOfWorksContractor[0]['value'];
      selectedTypeOfWork = selectedTypeOfWork.map(t => { return +t; });
      this.props.contractor.saveInfo('typeOfWorkApproved', selectedTypeOfWork);

      await this.props.contractor.updateContractorInfo(this.props.id, 'typeOfWorkApproved');
      this.props.noti.success('แก้ไขข้อมูลเรียบร้อย', 'สำเร็จ');
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      this.props.noti.error(`${error.message}`, 'ล้มเหลว');
    }
  }

  onTypeOfWorkChange(selectedItem, e) {
    let typeOfWork = e.split(',');
    let items = _.cloneDeep(this.state.typeOfWorksContractor);
    let item = items.find(i => {
      return i.key === selectedItem.key;
    });
    if (!_.isNil(item) && !_.isEmpty(item)) {
      item['value'] = typeOfWork;
    }
    this.setState({
      typeOfWorksContractor: items,
    });
  }
}

export default inject('contractor', 'typeofwork')(observer(AdminTypeOfWorkContractor));
